import React from "react";
import { Select } from "@/components/ui";
import useLadyService from "@/services/LadyService";

const DropdownLanguage = ({ size = "xs" }) => {
    const { city } = useLadyService();
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    const languages = [
        { id: 1, link: city === 'czechia' ? '/' : `/en/${city}`, title: "EN", value: "en" },
        { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
        { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
    ]
    if (!isJsdom) {
        return (
            <Select

                options={languages}
                size={size}
                arrowSize={"s"}
                sorted={true}
                name={"language"}
            ></Select>
        )
    } else {
        return (
            languages.map((link) => {
                return (<a href={link.link}>{link.title}</a>)
            })
        )
    }
};

export default DropdownLanguage