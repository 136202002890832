import React, {useEffect, useState} from "react";
import {Button, ErrorMessage, InputInLabel} from "../../../../ui";
import {useForm} from "react-hook-form";
import useLadyService from "@/services/LadyService";
import {LangAi} from "../../createProfile/pageComponent";
import {useSelector} from "react-redux";
import {getAgency, setAgency} from "@/stores/slices/userProfileSlice";
import showToast from "../../../../toast/Toast";
import {makeRequest} from "@/services/makeRequest";

export const AgencyDesc = () => {
    const {t, lang, dispatch} = useLadyService();

    const agency = useSelector(getAgency);
    const initialValues = {
        name: agency.name || "",
        website: agency.website || "",
        description: {
            en: agency.description?.en || "",
            cz: agency.description?.cz || "",
            ru: agency.description?.ru || ""
        },
        description_translate_to: agency.description_translate_to || []
    };
    const {register, handleSubmit, reset, setValue, watch, formState: {isDirty}} = useForm({
        mode: "onSubmit",
        defaultValues: {...initialValues}
    });


    useEffect(() => {
        reset({
            name: agency.name || "",
            website: agency.website || "",
            description: {
                en: agency.description?.en || "",
                cz: agency.description?.cz || "",
                ru: agency.description?.ru || ""
            },
            description_translate_to: agency.description_translate_to || []
        });
    }, [agency, reset]);

    const [langAi, setLangAi] = useState(lang);
    const [selectedLangsAi, setSelectedLangsAi] = useState(agency.description_translate_to || []);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if (isDirty) {

            const route = `user`;
            const method = "PUT";
            await makeRequest({route, method, payload: {...data}}).then((response) => {
                dispatch(setAgency(response.agency.data))
                showToast({
                    message: t("success"),
                    variant: "success",
                });
            }).catch((error) => {
                const res = error.response;
                switch (res?.status) {
                    case 401: {
                        showToast({
                            message: t("noauth"),
                            variant: "error",
                        });
                        break;
                    }
                    case 422: {
                        showToast({
                            message: t("oops"),
                            variant: "error",
                        });
                        break;
                    }
                    default: {

                    }
                }
            })
        }
    };

    const currentDescription = watch(`description.${langAi}.description`, "");
    useEffect(() => {
    }, [currentDescription])
    const descriptionLength = currentDescription?.length || 0;

    const handleChangeLangAi = async (newLang) => {
        const [currentDescription] = await Promise.all([watch(`description`)]);
        setValue(`description`, currentDescription || "");
        if (selectedLangsAi.includes(newLang)) {
            setSelectedLangsAi(selectedLangsAi.filter(lang => lang !== newLang))
        }
        setLangAi(newLang)
    }

    useEffect(() => {
        setValue(`description_translate_to`, selectedLangsAi, {shouldDirty: true});
    }, [selectedLangsAi, setValue])

    return (
        <form
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="d-flex gap-16">
                <fieldset>
                    <legend> {t("name")} {t("allagencies").split(" ")[1]}</legend>
                    <InputInLabel
                        type="text"
                        id="name_id"
                        placeholder={t("entername")}
                        register={{...register("name")}}
                        onChange={(e) => {
                            setValue(`name`, e.target.value, {shouldDirty: true});
                        }}
                        clazz="w-100"
                    >
                    </InputInLabel>
                </fieldset>

                <fieldset>
                    <legend>{t("yoursite")}</legend>
                    <InputInLabel
                        type="text"
                        id="website_id"
                        placeholder="https://example.com"
                        register={{...register("website")}}
                        onChange={(e) => {
                            setValue(`website`, e.target.value, {shouldDirty: true});
                        }}
                        clazz="w-100"
                    >
                    </InputInLabel>
                </fieldset>
            </div>

            <h3>{t("agencydesc")}</h3>

            <InputInLabel
                clazz="gap-16"
                type="textarea"
                id="agency-lang"
                placeholder=""
                value={currentDescription}
                onChange={(e) => {
                    setValue(`description.${langAi}.description`, e.target.value, {shouldDirty: true});
                }}
            >
                {t("symbols")}: {descriptionLength}/400
                {descriptionLength === 400 && (
                    <ErrorMessage message={`${t("maxval")} 400 ${t("symbols")}`}/>
                )}
            </InputInLabel>


            <div className="d-flex gap-8 align-center mt-16">
                <LangAi
                    langAi={langAi}
                    setLangAi={handleChangeLangAi}
                    selectedLangsAi={selectedLangsAi}
                    setSelectedLangsAi={setSelectedLangsAi}
                />

                <Button
                    size="s"
                    disabled={!isDirty}
                    buttonType={isDirty ? "submit" : "button"}
                    clazz="button_outline--green-accent justify-center ml-auto w-160"
                >
                    {t("save")}
                </Button>
            </div>
        </form>
    );
};
