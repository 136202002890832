import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";
import { setTheme, toggleTheme } from "@/stores/slices/userSlice";


import "./toggle.scss";
import SwitchTheme from "../switchTheme/SwitchTheme";

const Toggle = (props) => {
  const {
    id,
    name,
    onChange,
    link,
    title,
    titleDefault,
    value,
    defaultValue,
    disabled,
    counter,
    checked,
    onClick,
    clazz = "",
    readOnly,
    register,
    type = 'default',
  } = props;

  const {
    theme,
    dispatch
  } = useLadyService();

  const SwitchTheme = ( ) => {
    dispatch(toggleTheme())
  }


  switch(type){
    case 'theme': {
      return(
        <div className={`toggle-switch theme`}>
          <input
            {...register}
            onClick={SwitchTheme}
            id={id}
            readOnly={readOnly}
            name={name}
            type="checkbox"
            checked={theme == 'light' ? true : false}
          />
        </div>
      )
    }
    default:{
      return (
        <div className={`toggle-switch${!!titleDefault ? " defaulttitle" : ""}${disabled ? " _disabled" : ""}${clazz ? ` ${clazz}` : ""}`}>
          {link ? (
            <Link
              to={`${link}`}
              className="hover-line p"
              data-counter={counter === 0 ? null : counter}
            >
              {title}
            </Link>
          ) : null}
    
          {!!titleDefault ? (
            <span className="p2 color-main">{titleDefault}</span>
          ) : null}
    
          <input
            {...register}
            onClick={onClick}
            id={id}
            readOnly={readOnly}
            name={name}
            value={value}
            type="checkbox"
            disabled={disabled}
            onChange={onChange}
            checked={checked}
            defaultValue={defaultValue}
          />
        </div>
      );
    }  
  }
 
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Toggle;
