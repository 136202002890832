import { Icon } from "@/components/ui";
import "./verHeader.scss";
import { HashLink } from "react-router-hash-link";
import { AddHeader } from "../../pageComponets";

import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import {format, fromUnixTime} from "date-fns";

const VerHeader = (props) => {
  const { openVer, openHealth, setAgencySelectCheckbox, data, setIsOpenImport } = props;
  const { windowWidth } = useLadyService();

  const { t } = useTranslation("translation");

  const { healthy_at, verified_at, verify_count } = data;

  const currentTimestamp = new Date().getTime();

  const isSqure = windowWidth < 500;

  const healthy =  healthy_at ? format(fromUnixTime(healthy_at), 'dd.MM.yyyy') : null;
  const verified =  verified_at ? format(fromUnixTime(verified_at), 'dd.MM.yyyy') : null;

  const isVerifyActive = !!verified_at && !!verify_count;

  const handleOpenImport = () => {
    setIsOpenImport(true);
  };

  const Money = () => {
    switch (true) {
      case windowWidth >= 700: {
        return (
          <span className="ver__block__info">
            <span className="p2">{t("incomesmeth")}</span>
            <span className="p3 color-600">{t("getmoneyonline")}</span>
          </span>
        );
      }

      default: {
        return <span className="p3 ml-4">{t("moneyfromads")}</span>;
      }
    }
  };

  return (
    <>
      <AddHeader handleOpenImport={handleOpenImport} />
      <div className="ver__root">
        {windowWidth > 500 && (
          <>
            <div onClick={openVer} className={"td-none ver__block__control"}>
              <Icon
                spritePath={"verify-fill"}
                size={"l"}
                clazz={isVerifyActive ? "_green" : "_red"}
              />
              {windowWidth > 800 && (
                <div className="ver__block__info">
                  {isVerifyActive ? (
                    <>
                      <span className="p2 color-green">
                        {t("adsver")} ({verify_count})
                      </span>
                      <span className="p3 color-600">
                        {t("valid")} {t("until")} {verified}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="p2 color-green">{t("adsver")}</span>
                      <span className="p3  color-red-700">
                        {t("not")} {t("valid")}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
  
            <div onClick={openHealth} className={"td-none ver__block__control"}>
              <Icon
                spritePath={"health-fill"}
                size={"l"}
                clazz={
                  !!healthy_at && currentTimestamp < healthy_at * 1000
                    ? "_green"
                    : "_red"
                }
              />
              {windowWidth > 800 && (
                <div className="ver__block__info">
                  <span className="p2 color-green">{t("healthy")}</span>
                  {!!healthy_at && currentTimestamp < healthy_at * 1000 ? (
                    <span className="p3 color-600">
                      {t("valid")} {t("until")} {healthy}
                    </span>
                  ) : (
                    <span className="p3 color-red-700">
                      {t("not")} {t("valid")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </>
        )}
  
        {windowWidth > 1200 && (
          <HashLink
            to="/guide#eroCoins"
            className={"td-none ver__block__control-gray"}
            target="_blank"
            onClick={()=>{setAgencySelectCheckbox([])}}
          >
            <Icon spritePath={"euro"} size={"l"} />
            {!isSqure && <Money />}
          </HashLink>
        )}
      </div>
    </>
  );
};

export default VerHeader;
