import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Icon, InputInLabel } from "../index";

import "./note.scss";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
} from "@/stores/slices/popupSlice";

const Note = ({ note, profileId }) => {
  const { t } = useTranslation("translation");

  const { dispatch, userType, setAvialableForUser } = useLadyService();

  const [noteContent, setNoteContent] = useState(note);

  const onChangeNote = (e) => {
    const targetValue = e.target.value;
    setNoteContent(targetValue);
  };

  const [clicked, setClicked] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = ({ profileId, e }) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `note/${profileId}`;
        const method = noteContent ? "PUT" : "DELETE";

        const payload = !!noteContent ? { content: noteContent } : null;

        await makeRequest({ route, method, payload });
      } catch (res) {
        console.log(res, res.method);
      }
    };

    fetchData().then(setClicked(false));
  };

  const handleClick = () => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("note"));
    } else {
      setClicked(true);
    }
  };

  return (
    <form
      className={`button button-xs button_outline--black note`}
      method={"PUT"}
      onClick={() => handleClick()}
      onSubmit={(e) => handleSubmit(onSubmit({ profileId, e }))}
    >
      {clicked ? (
        <fieldset>
          <legend></legend>
          <InputInLabel
            clazz={"p-0"}
            register={{ ...register("content") }}
            id="modelNote"
            onChange={onChangeNote}
            value={noteContent ? noteContent : ""}
          >
            <button className={"d-flex align-center"} type={"submit"}>
              <Icon spritePath={"save"} size={"m"} />
            </button>
          </InputInLabel>
        </fieldset>
      ) : (
        <>
          {noteContent ? (
            noteContent
          ) : (
            <>
              {t("note")} <span className={"_gray"}>({t("visibleforme")})</span>
            </>
          )}

          <Icon spritePath={"edit"} size={"m"} />
        </>
      )}
    </form>
  );
};

export default Note;
