import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import useLadyService from "../../../services/LadyService";
import showToast from "../../toast/Toast";
import { Button, Checkbox, Icon, InputInLabel } from "../../ui";
import { Link } from "react-router-dom";
import { Popup } from "../index";
import { makeRequest } from "../../../services/makeRequest";

const FastVer = (props) => {
  const { whatsapp, telegram, open, setOpen, profiles, setIsVideoRequest } = props;
  const { t, setMediaSize, userBalance, userType } = useLadyService();

  const sortedProfiles = profiles && !!profiles.length
    ? profiles
        .filter((item) => !item.verified_at)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
    : [];

  const [valueLoad, setValueLoad] = useState(false);
  const [whatsappData, setWhatsappData] = useState(whatsapp);
  const [isTelegram, setIsTelegram] = useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);


  const obj = isTelegram
    ? { telegram: string().required() }
    : isWhatsapp
    ? {
        whatsapp: string().test("phone-is-empty", function () {
          return !!whatsappData.replace("+", "");
        }),
      }
    : {};

  const formSchema = object().shape(obj);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [submitError, setSubmitError] = useState("");

  const onSubmit = (data, e) => {
    const { telegram: newTelegram } = data;

    if (!newTelegram && !whatsappData.replace("+", "")) {
      setSubmitError(true);
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

      return;
    }
    if(!userBalance) {
      showToast({
        message: t('insuff'),
        variant: "error",
        type: "insuff",
        userType: userType
      });
      return
    }
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/profile/bulk-verify`;
        const method = "POST";
        const payload = {
          is_video: true,
          ...(data.telegram && { telegram: data.telegram }),
          ...(data.whatsapp.replace('+', '') && { whatsapp: data.whatsapp.replace(/\s/g, "").replace("+", "") }),
          profiles: selectedAds,
        };
        const result = await makeRequest({ route, method, payload });
        if (result) {
          setIsVideoRequest(true)
          showToast({
            message: t("waitadmincall"),
            variant: "success",
          });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
      handleClose();
    };
    fetchData();
  };

  const [selectedAds, setSelectedAds] = useState([]);

  const handleAddChange = (slug) => {
    if (selectedAds.includes(slug)) {
      setSelectedAds((prev) => prev.filter((item) => item !== slug));
    } else {
      setSelectedAds((prev) => [...prev, slug]);
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const handleFilter = (e) => {
    const val = e.currentTarget.value;
    setSearchValue(val);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    setValueLoad(false);
    setValue("telegram", telegram);
    setValue("whatsapp", whatsappData);
    setValueLoad(true);
  }, [setValue, telegram, whatsappData]);

  return (
    <Popup
      open={open}
      setOpen={handleClose}
      clazz={"gap-16"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="mt-48">
        {t("videofotograph")} {t("verification")}
      </h2>
      <div ref={inputRef} className="d-flex fd-row justify-sb gap-12">
        {/* <InputInLabel
          clazz={"fd-row"}
          id={"orderWhatsApp"}
          type={"checkbox"}
          onChange={onChangeWhatsapp}
          checked={isWhatsapp}
        >
          <Icon spritePath={"whatsapp"} size={"m"} />
        </InputInLabel> */}
        <fieldset>
          <legend>{errors.whatsapp ?
              <span className={"input-label__error"}>
                {errors.whatsapp.message}
              </span>
            : <span>whatsapp</span>}</legend>
          <InputInLabel
            type={"tel"}
            id={"whatsappPhone"}
            placeholder={t("phonenumber")}
            register={{ ...register("whatsapp") }}
            maskValue={whatsappData}
            setMaskValue={setWhatsappData}
            valueLoad={valueLoad}
            required={isWhatsapp}
            onChange={(e) => {
              if (errors.whatsapp) {
                clearErrors("whatsapp");
              }
              if (submitError) {
                setSubmitError("");
              }
            }}
            clazz={
              isWhatsapp
                ? `${errors?.whatsapp ? " error" : ""} w-100`
                : "w-100"
            }
          >
           
          </InputInLabel>
        </fieldset>
      </div>

      <div className="d-flex fd-row justify-sb gap-12">
        {/* <InputInLabel
          clazz={"fd-row"}
          id={"orderTelegram"}
          type={"checkbox"}
          onChange={onChangeTelegram}
          checked={isTelegram}
        >
          <Icon spritePath={"telegram"} size={"m"} />
        </InputInLabel> */}

        <fieldset>
          <legend>{errors.telegram ?
              <span className={"input-label__error"}>
                {errors.telegram.message}
              </span>
            : <span>telegram</span>}</legend>
          <InputInLabel
            type={"input"}
            id={"telegram"}
            placeholder={"Telegram"}
            register={{ ...register("telegram") }}
            clazz={
              isTelegram
                ? `${errors?.telegram ? " error" : ""} w-100`
                : "w-100"
            }
            required={isTelegram}
            onChange={(e) => {
              if (errors.telegram) {
                clearErrors("telegram");
              }
              if (submitError) {
                setSubmitError("");
              }
            }}
          >
          </InputInLabel>
        </fieldset>
      </div>
      {submitError && (
        <span className="error color-red-700">{t("selectvar")}</span>
      )}
      <span className={"color-700 text-center"}>{t("moderwillcall")}</span>
      <InputInLabel
        placeholder={t("searchmodel")}
        onChange={handleFilter}
        value={searchValue}
        size={"s"}
      />
      <div className="agency-block__content">
        {!!sortedProfiles?.length &&
          open &&
          sortedProfiles
            .filter((item) =>
              item.name.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((item) => {
              return (
                <div key={item.id} className="agency-block w-100">
                  <img src={setMediaSize(item.main_photo, "xs")} alt="girl" />
                  <Link
                    to={`/profile/${item.slug}`}
                    className="agency-block__link-to"
                  >
                    {item.name}
                  </Link>
                  <Checkbox
                    border={false}
                    checked={selectedAds.includes(item.slug)}
                    onChange={() => handleAddChange(item.slug)}
                  />
                </div>
              );
            })}
      </div>
      <p className="text-center">
        {t("numberofmodels") + ": "}
        <span className="color-green">{selectedAds.length}</span>
      </p>
      <p className="p1 color-main text-center">
        {t("pricever") + ` \u2013`}{" "}
        <span className="fz-24 currency--erocoin">
          {selectedAds.length * 10}
        </span>
      </p>
      <div className="d-flex gap-12">
        <Button
          size={"l"}
          clazz={"button--secondary justify-center w-100"}
          onClick={handleClose}
        >
          {t("cancel")}
        </Button>

        <Button
          disabled={!selectedAds.length}
          size={"l"}
          buttonType={"submit"}
          clazz={"button--green justify-center w-100"}
        >
          {t("confirm")}
        </Button>
      </div>
    </Popup>
  );
};

export default FastVer;
