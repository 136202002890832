
import { Button, Select, InputInLabel } from "@/components/ui";
import MultiRangeSlider from "multi-range-slider-react";
import bodyShape from "@/assets/img/body_shape.svg";
import bodyShapeLight from "@/assets/img/body_shape-light.svg";
import useLadyService from "@/services/LadyService";
import "./appearance.scss";
import { Loader } from "@/components/ui";
import { useState, useEffect, useCallback } from "react";
import { makeRequest } from "@/services/makeRequest";
import { useForm } from "react-hook-form";
import showToast from "../../../../../../toast/Toast";
import debounce from "lodash.debounce";
import { number, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import map from "lodash.map";
import {
  AttentionBrick,
  ErrorMessage,
  LoadingButton,
} from "../../../../../../ui";
import "./appearance.scss";
import LangAi from "../../langAi/LangAI";
import { useSelector } from "react-redux";
import { getDescTranslateTo } from "@/stores/slices/translateAiSlice";
import { mergeTranslations } from "@/helper/mergeTranslations";
import {Popup} from "../../../../../../popups";

const MainPart = ({
  isMobile,
  formData,
  setOrientation,
  gender,
  data,
  setGender,
  sortedGender,
  options,
  register,
  errors,
  setHeightValue,
  setWeightValue,
  breastCategory,
  setBreastCategory,
  setBreastType,
  breastType,
  isTablet,
  setHairLength,
  setHairType,
  hairType,
  setHairIntim,
  hairIntim,
  eyesType,
  setEyesType,
  setEyesColor,
  eyesColor,
  setLips,
  lips,
  setTatoo,
  tatoo,
  setPiercing,
  piercing,
  setSmoking,
  smoking,
  theme,
  setShoulders,
  shoulders,
  setWaist,
  waist,
  setHips,
  hips,
  isDescLoad,
  descValue,
  descChange,
  descValueLength,
  handleSubmit,
  generateText,
  setPerfume,
  setPenis,
  langAi,
  setLangAi,
  selectedLangsAi,
  setSelectedLangsAi,
}) => {
  const { t } = useLadyService();
  
  return (
    <div className={`create-page__form appearance`}>
      <div className={`first-row ${isMobile ? "fd-column" : ""}`}>
        <SelectWithButtons
          defaultValue={formData?.orientation}
          type={"radio"}
          buttonValue={setOrientation}
          buttons={
            gender === "female"
              ? data.orientation1
              : gender === "male" || gender === "trans"
              ? data.orientation2
              : data.orientation1
          }
          clazz={"w-100"}
        >
          <fieldset>
            <legend>{t("selectgender")}</legend>
            <Select
              name={"aboba"}
              onChange={setGender}
              options={formData?.gender ? sortedGender : options.gender}
              arrowSize={"m"}
              withoutTitle={true}
              clazzWrapper={'select-field'}
            />
          </fieldset>
         
        </SelectWithButtons>
        <div className="d-flex w-100 gap-12">
          <fieldset>
            <legend>{errors?.height ? <ErrorMessage message={errors.height.message} /> : `${t("height")}, ${t("cm")}`}</legend>
            <InputInLabel
              type={"number"}
              id={"create-height"}
              value={formData.height}
              placeholder={"150"}
              register={{ ...register("height") }}
              clazz={`${errors.height && "_error"}`}
              onChange={(e) => setHeightValue(e)}
              max={250}
            >
            </InputInLabel>
          </fieldset>
          <fieldset>
            <legend>{errors?.weight ? <ErrorMessage message={errors.weight.message} /> : `${t("weight")}, ${t("kg")}`}</legend>
            <InputInLabel
              type={"number"}
              id={"create-weight"}
              value={formData.weight}
              placeholder={"50"}
              register={{ ...register("weight") }}
              clazz={`${errors.weight && "_error"}`}
              onChange={(e) => setWeightValue(e)}
              max={250}
            >
            </InputInLabel>
          </fieldset>
          {isMobile &&
            (gender === "male" ||
              gender === "trans" ||
              gender === "couple") && (
              <fieldset className="w-140p">
                <legend>{errors?.penis ? <ErrorMessage message={errors.penis.message} /> : `${t("penissize")}, ${t("cm")}`}</legend>
                <InputInLabel
                  type={"number"}
                  id={"create-penis"}
                  value={Number(formData?.penis_size)}
                  placeholder={"15"}
                  register={{ ...register("penis") }}
                  clazz={`${errors.penis && "_error"}  ${
                    gender === "male" ? "width-80" : ""
                  }`}
                  max={45}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (e.target.value > 45) {
                      newValue = 45;
                    }
  
                    setPenis(Number(newValue));
                  }}
                >
                </InputInLabel>
              </fieldset >
            )}
        </div>
        {isMobile &&
          (gender === "female" ||
            gender === "trans" ||
            gender === "couple") && (
            <SelectWithButtons
              defaultValue={breastCategory}
              buttonValue={setBreastCategory}
              buttons={data.breastType}
              type={"radio"}
            >
              <fieldset>
                <legend>{t("sizebreast")}</legend>
                <Select
                  onChange={setBreastType}
                  name={"create-breast"}
                  options={options.breast}
                  arrowSize={"m"}
                  defaultTitle={
                    !!breastType
                      ? options?.breast?.filter(
                          (item) => item.value === breastType
                        )[0]?.title
                      : ""
                  }
                  withoutTitle={true}
                  postApi={true}
                  clazzWrapper={'select-field'}
                />
              </fieldset>
            </SelectWithButtons>
          )}
      </div>
      <div className={`d-flex gap-4 ${isMobile ? "fd-column-rev" : ""}`}>
        <div className="d-flex fd-column gap-16 justify-sb w-100">
          <div className="d-flex gap-12 w-100">
            {gender === "male"
              ? null
              : !isMobile && (
                  <SelectWithButtons
                    defaultValue={breastCategory}
                    buttonValue={setBreastCategory}
                    buttons={data.breastType}
                    type={"radio"}
                    clazz={"w-100"}
                  >
                    <fieldset>
                      <legend>{t("sizebreast")}</legend>
                      <Select
                        onChange={setBreastType}
                        name={"create-breast"}
                        options={options.breast}
                        arrowSize={"m"}
                        defaultTitle={
                          !!breastType
                            ? options?.breast?.filter(
                                (item) => item.value === breastType
                              )[0]?.title
                            : ""
                        }
                        withoutTitle={true}
                        postApi={true}
                        clazzWrapper={'select-field'}
                      />
                    </fieldset>
                  </SelectWithButtons>
                )}

            {!isMobile &&
              (gender === "male" ||
                gender === "trans" ||
                gender === "couple") && (
                <fieldset className="w-140p">
                  <legend>{errors?.penis ? <ErrorMessage message={errors.penis.message} /> : `${t("penissize")}, ${t("cm")}`}</legend>
                  <InputInLabel
                    type={"number"}
                    id={"create-penis"}
                    value={Number(formData?.penis_size)}
                    placeholder={"15"}
                    register={{ ...register("penis") }}
                    clazz={`${errors.penis && "_error"}  ${
                      gender === "male" ? "" : "penis-label"
                    }`}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (e.target.value > 45) {
                        newValue = 45;
                      }
  
                      setPenis(Number(newValue));
                    }}
                    max={45}
                  >
                  </InputInLabel>
                </fieldset>
              )}
          </div>
          <div
            className={`d-flex gap-12 ${isTablet ? "fd-column" : ""} ${
              isMobile ? "fd-column" : ""
            }`}
          >
            <SelectWithButtons
              defaultValue={formData?.hair_length}
              buttonValue={setHairLength}
              buttons={data.hairType}
              type={"radio"}
              clazz={"w-100"}
            >
              <fieldset>
                <legend>{t("haircolor")}</legend>
                <Select
                  onChange={setHairType}
                  name={"create-hair"}
                  options={options.hair}
                  arrowSize={"m"}
                  defaultTitle={
                    hairType
                      ? options?.hair?.filter((item) => item.value === hairType)[0]?.title
                      : ""
                  }
                  withoutTitle={true}
                  postApi={true}
                  clazzWrapper={'select-field'}
                />
              </fieldset>
            </SelectWithButtons>
            <fieldset>
              <legend>{t("pubichair")}</legend>
              <Select
                onChange={setHairIntim}
                name={"create-hairintim"}
                options={options.hairIntim}
                clazzWrapper={isMobile ? "select-field" : "select-field w-50"}
                arrowSize={"m"}
                defaultTitle={
                  hairIntim
                    ? options?.hairIntim?.filter(
                        (item) => item.value === hairIntim
                      )[0]?.title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
              />
            </fieldset>
          </div>
          <div
            className={`d-flex gap-12 ${isTablet ? "fd-column" : ""} ${
              isMobile ? "fd-column" : ""
            }`}
          >
            <SelectWithButtons
              defaultValue={eyesType}
              buttonValue={setEyesType}
              buttons={data.eyesType}
              clazz={"w-100"}
            >
              <fieldset>
                <legend>{t("eyecolor")}</legend>
                <Select
                  onChange={setEyesColor}
                  name={"create-eyes"}
                  options={options.eyes}
                  arrowSize={"m"}
                  defaultTitle={
                    eyesColor
                      ? options?.eyes?.filter((item) => item.value === eyesColor)[0]
                          ?.title
                      : ""
                  }
                  withoutTitle={true}
                  postApi={true}
                  clazzWrapper={'select-field'}
                />
              </fieldset>
            </SelectWithButtons>
            <fieldset>
              <legend>{t("lips")}</legend>
              <Select
                onChange={setLips}
                name={"create-hairintim"}
                options={options.leaps}
                clazz={isMobile ? "" : "w-50"}
                arrowSize={"m"}
                defaultTitle={
                  lips && options?.leaps?.length
                    ? options?.leaps?.filter((item) => item.value === lips)[0]?.title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
                clazzWrapper={'select-field'}
              />
            </fieldset>
          </div>
          <div className="d-flex gap-12">
            <fieldset>
              <legend>{t("tattoos")}</legend>
              <Select
                onChange={setTatoo}
                name={"create-tatu"}
                options={options.tatu}
                clazzWrapper={"w-100 select-field"}
                arrowSize={"m"}
                defaultTitle={
                  tatoo
                    ? options?.tatu?.filter((item) => item.value === tatoo)[0]?.title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
                
              />
            </fieldset>
            <fieldset>
              <legend>{t("piercing")}</legend>
              <Select
                onChange={setPiercing}
                name={"create-pirsing"}
                options={options.tatu}
                clazzWrapper={"w-100 select-field"}
                arrowSize={"m"}
                defaultTitle={
                  piercing
                    ? options?.tatu?.filter((item) => item.value === piercing)[0]
                        .title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
                
              />
            </fieldset>
            <fieldset>
              <legend>{t("smoking")}</legend>
              <Select
                onChange={setSmoking}
                name={"create-smoking"}
                options={options.smoking}
                clazzWrapper={"w-100 select-field"}
                arrowSize={"m"}
                defaultTitle={
                  smoking
                    ? options?.smoking?.filter((item) => item.value === smoking)[0]
                        .title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
              />
            </fieldset>
          </div>
        </div>

        <div className={`form-appearance__right ${isMobile ? "" : "ml-auto"}`}>
          <span>{t("proportions")}</span>
          <img src={theme === "light" ? bodyShapeLight : bodyShape} alt="" />
          <div className="form-appearance__right-range">
            <CreateRange
              min={0}
              max={140}
              setData={setShoulders}
              defaultValue={shoulders}
            />
            <CreateRange
              min={0}
              max={120}
              setData={setWaist}
              defaultValue={waist}
            />
            <CreateRange
              min={0}
              max={160}
              setData={setHips}
              defaultValue={hips}
            />
          </div>
        </div>
      </div>

      <fieldset>
        <legend>{t("myperfume")}</legend>
        <InputInLabel
          type={"text"}
          id={"create-perfume"}
          placeholder={""}
          register={{ ...register("perfume") }}
          clazz={`${isMobile ? "w-100" : "w-mc"}`}
          value={formData?.perfume}
          onChange={(e) => setPerfume(e.target.value)}
        >
        </InputInLabel>
      </fieldset>
      <h3 className="mb-0">{t("descandpef")}</h3>

      <LangAi
        langAi={langAi}
        setLangAi={setLangAi}
        selectedLangsAi={selectedLangsAi}
        setSelectedLangsAi={setSelectedLangsAi}
      />
      <div className="form-services__preference">
        <div className={`form-services__preference--inputs`}>
          {!isDescLoad ? (
            !(
              !!formData.description_translate_to.length &&
              formData.description_translate_to.includes(langAi)
            ) ? (
                <InputInLabel
                  clazz={"gap-16"}
                  type={"textarea"}
                  id={"create-AI"}
                  placeholder={""}
                  value={descValue[langAi]?.description}
                  onChange={descChange}
                >
                {t("symbols") + ":"} {descValueLength || 0}
                /400
                {descValueLength === 400 && (
                  <ErrorMessage
                    message={`${t("maxval")} 400 ${t("symbols")}`}
                  />
                )}
                </InputInLabel>
            ) : (
              <div className="loader-container">
                <Loader />
                <span className="d-flex justify-center">{t("processing")}</span>
              </div>
            )
          ) : (
            <div className="loader__block">
              <Loader />
            </div>
          )}
        </div>
        <div className={`d-flex fd-column justify-sb gap-16`}>
          <AttentionBrick />
          <span className="color-main">
            {t("gentext")} {t("yourappearance")}
          </span>
          <Button
            size={"m"}
            square={false}
            clazz={`button--primary w-100 mt-12`}
            onClick={handleSubmit(generateText)}
          >
            {t("genbutton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const PopupButtons = ({ isLoading, setOpen }) => {
  const { t } = useLadyService();
  return (
    <div className="create-form__buttons">
      <Button
        size={"l"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => setOpen(false)}
        disabled={isLoading}
      >
        {t("cancel")}
      </Button>

      <LoadingButton
        buttonType={"submit"}
        size={"l"}
        square={false}
        isLoading={isLoading}
        clazz="button--green min-w-160"
      >
        {t("save")}
      </LoadingButton>
    </div>
  );
};

const DefaultButtons = ({ backStep, isLoading }) => {
  const { t } = useLadyService();

  return (
    <div className="create-form__buttons">
      <Button
        size={"l"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => backStep()}
        disabled={isLoading}
      >
        {t("back")}
      </Button>

      <LoadingButton
        buttonType={"submit"}
        size={"l"}
        isLoading={isLoading}
        clazz="button--green min-w-160"
      >
        {t("continue")}
      </LoadingButton>
    </div>
  );
};

const SelectWithButtons = ({
  children,
  withoutSelect = false,
  buttons,
  buttonValue,
  type = "checkbox",
  defaultValue,
  clazz,
}) => {
  const [selectedButton, setSelectedButton] = useState(
    type === "radio"
      ? defaultValue ?? ""
      : !!defaultValue?.length
      ? defaultValue
      : []
  );

  const handleButtonClick = (value) => {
    if (type === "radio") {
      setSelectedButton(value);
      buttonValue(value);
    } else {
      const isIdAlreadySelected = selectedButton.includes(value);
      if (isIdAlreadySelected) {
        setSelectedButton((prev) => prev.filter((i) => i !== value));
        buttonValue((prev) => prev.filter((i) => i !== value));
      } else {
        setSelectedButton((prev) => [...prev, value]);
        buttonValue((prev) => [...prev, value]);
      }
    }
  };

  useEffect(() => {
    setSelectedButton(
      type === "radio"
        ? defaultValue ?? ""
        : !!defaultValue?.length
        ? defaultValue
        : []
    );
  }, []);

  return (
    <div className={`create-page__input-container ${clazz}`}>
      {!withoutSelect && children}
      <div className="create-page__input-buttons">
        {buttons.map((i) => {
          return (
            <label
              className={`checkbox-button${
                type === "radio"
                  ? selectedButton === i.value
                    ? " checkbox-button_checked"
                    : ""
                  : selectedButton.includes(i.value)
                  ? " checkbox-button_checked"
                  : ""
              }`}
              htmlFor={i.id}
              tabIndex={1}
              key={i.value}
            >
              <input
                onChange={() => handleButtonClick(i.value)}
                tabIndex={0}
                id={i.id}
                type={type}
                name={i.name}
                checked={selectedButton === i.value}
                value={i.value}
              />
              {i.title}
            </label>
          );
        })}
      </div>
    </div>
  );
};

const CreateRange = ({ min, max, defaultValue, setData }) => {
  const [value, setValue] = useState(defaultValue ?? 0);
  const [maxValue, setMaxValue] = useState(defaultValue ?? 0);

  return (
    <div className="create-range">
      <input
        type="number"
        value={value}
        onChange={(e) => setMaxValue(e.target.value)}
      ></input>
      <MultiRangeSlider
        min={min}
        max={max}
        maxValue={maxValue}
        minValue={0}
        step={1}
        stepOnly={true}
        className={"create-range__input"}
        ruler={false}
        label={false}
        onInput={(e) => {
          setData(e.maxValue);
          setValue(e.maxValue);
          setMaxValue(e.maxValue);
        }}
      />
    </div>
  );
};

const AppearanceGlobal = ({
  setAvailableSteps,
  changeStep,
  rootElem,
  formData,
  setFormData,
  setFilled,
  open,
  setOpen,
}) => {
  const { t, theme, token, lang } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);

  const updatedTranslation = useSelector(getDescTranslateTo);
  useEffect(() => {
    if (!!formData.description_translate_to.length) {
      setFormData(prevFormData => {
        const updatedLanguages = prevFormData.description_translate_to.filter(lang =>
          !updatedTranslation.some(translation => translation.hasOwnProperty(lang))
        );
    
        const newFormData = { ...prevFormData };
        const result = mergeTranslations(newFormData.description, updatedTranslation);
        return {
          ...newFormData,
          description: result,
          description_translate_to: updatedLanguages,
        };
      });
    }
  }, [updatedTranslation]);
  
  const options = {
    tatu: [
      { id: 92, title: t("none"), value: "no" },
      { id: 91, title: t("few"), value: "few" },
      { id: 90, title: t("many"), value: "many" },
    ],
    smoking: [
      { id: 89, title: t("none"), value: "no" },
      { id: 88, title: t("rarely"), value: "sometimes" },
      { id: 87, title: t("often"), value: "regularly" },
    ],
    ethnos: [
      { id: 99, title: t("white"), value: "white" },
      { id: 98, title: t("asian"), value: "asian" },
      { id: 97, title: t("african"), value: "african" },
      { id: 96, title: t("arabic"), value: "arabic" },
      { id: 95, title: t("indian"), value: "indian" },
      { id: 94, title: t("tanned"), value: "tanned" },
      { id: 93, title: t("mixed"), value: "mixed" },
      { id: 93, title: t("latin"), value: "latin" },
    ],
    breast: [
      { id: 1, title: "0(AA)", value: "AA" },
      { id: 2, title: "1(A)", value: "A" },
      { id: 3, title: "2(B)", value: "B" },
      { id: 4, title: "3(C)", value: "C" },
      { id: 5, title: "4(D)", value: "D" },
      { id: 6, title: "5(E)", value: "E" },
      { id: 7, title: "6(F)", value: "F" },
      { id: 8, title: "7(G)", value: "G" },
      { id: 9, title: "8(H)", value: "H" },
    ],
    hair: [
      { id: 7, title: t("blond"), value: "blond" },
      { id: 8, title: t("brown"), value: "brown" },
      { id: 8, title: t("brunette"), value: "brunette" },
      { id: 10, title: t("ginger"), value: "red" },
    ],

    hairIntim: [
      {
        id: "create-shaved",
        title: t("shaved"),
        value: "shaved",
      },
      {
        id: "create-haircut",
        title: t("cut"),
        value: "cut",
      },
      {
        id: "create-intimNature",
        title: t("natural"),
        value: "natural",
      },
    ],
    eyes: [
      {
        id: "create-eyes-blue",
        title: t("blue"),
        value: "blue",
      },
      {
        id: "create-eyes-green",
        title: t("green"),
        value: "green",
      },
      {
        id: "create-eyes-brown",
        title: t("brownyies"),
        value: "brown",
      },
      {
        id: "create-eyes-grey",
        title: t("gray"),
        value: "gray",
      },
    ],
    leaps: [
      {
        id: "create-leaps-type",
        title: t("natural"),
        value: "natural",
      },
      {
        id: "create-leaps-type",
        title: t("enhanced"),
        value: "enhanced",
      },
    ],

    gender: [
      { id: 67, title: t("woman"), value: "female" },
      { id: 68, title: t("man"), value: "male" },
      { id: 68, title: t("trans"), value: "trans" },
      { id: 69, title: t("couples"), value: "couple" },
    ],
  };

  const formSchema = object().shape({
    height: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .min(45, `${t("minimum")} 45`)
      .max(250, `${t("maxval")} 250`)
      .nullable(true),

    weight: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .min(45, `${t("minimum")} 45`)
      .max(250, `${t("maxval")} 250`)
      .nullable(true),

    penis: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .nullable(true),
  });

  const data = {
    breastType: [
      {
        id: "create-nature",
        title: t("natural"),
        name: "breastType",
        value: "nature",
      },
      {
        id: "create-sil",
        title: t("silicone"),
        name: "breastType",
        value: "sil",
      },
    ],
    hairType: [
      {
        id: "create-short",
        title: t("short"),
        name: "hairType",
        value: "short",
      },
      {
        id: "create-middle",
        title: t("mediuma"),
        name: "hairType",
        value: "medium",
      },
      {
        id: "create-long",
        title: t("long"),
        name: "hairType",
        value: "long",
      },
    ],
    orientation1: [
      { id: 56, title: t("hetero"), value: "straight" },
      { id: 27, title: t("lesbi"), value: "lesbian" },
      { id: 54, title: t("bi"), value: "bisexual" },
    ],
    orientation2: [
      { id: 87, title: t("hetero"), value: "straight" },
      { id: 86, title: t("homo"), value: "homosexual" },
      { id: 85, title: t("bi"), value: "bisexual" },
    ],
    eyesType: [
      {
        id: "create-eyes-lenses",
        title: t("lenses"),
        name: "eyes-type",
        value: "lenses",
      },
      {
        id: "create-eyes-glasses",
        title: t("glasses"),
        name: "eyes-type",
        value: "glasses",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [breastType, setBreastType] = useState(
    formData?.breast_size ? formData?.breast_size : ""
  );

  const [breastCategory, setBreastCategory] = useState(
    formData?.silicone === false
      ? "nature"
      : formData?.silicone === true
      ? "sil"
      : null
  );

  const [hairType, setHairType] = useState(
    formData?.hair ? formData?.hair : ""
  );
  const [hairLength, setHairLength] = useState(
    formData?.hair_length ? formData?.hair_length : ""
  );
  const [hairIntim, setHairIntim] = useState(
    formData?.intim_hairstyle ? formData?.intim_hairstyle : ""
  );
  const [eyesColor, setEyesColor] = useState(
    formData?.eyes ? formData?.eyes : ""
  );
  const [eyesType, setEyesType] = useState([
    formData?.have_glasses ? "glasses" : "",
    formData?.have_lenses ? "lenses" : "",
  ]);
  const [lips, setLips] = useState(formData?.lips ? formData?.lips : "");
  const [tatoo, setTatoo] = useState(formData?.tatoo ? formData?.tatoo : "");
  const [piercing, setPiercing] = useState(
    formData?.piercing ? formData?.piercing : ""
  );
  const [smoking, setSmoking] = useState(
    formData?.smoking ? formData?.smoking : ""
  );
  const [shoulders, setShoulders] = useState(
    formData?.shoulders ? formData?.shoulders : 0
  );
  const [hips, setHips] = useState(formData?.hips ? formData?.hips : 0);
  const [waist, setWaist] = useState(formData?.waist ? formData?.waist : 0);

  const [height, setHeight] = useState(formData?.height ? formData?.height : 0);
  const [weight, setWeight] = useState(formData?.weight ? formData?.weight : 0);
  const [perfume, setPerfume] = useState(
    formData?.perfume ? formData?.perfume : null
  );
  const [penis, setPenis] = useState(formData?.penis ? formData?.penis : 0);

  const [orientation, setOrientation] = useState(
    formData?.orientation ? formData?.orientation : "straight"
  );
  const [gender, setGender] = useState(
    formData?.gender ? formData?.gender : ""
  );

  const [descValue, setDescValue] = useState(
    !!formData?.description
      ? formData?.description
      : {
          en: { description: null },
          ru: { description: null },
          cz: { description: null },
        }
  );

  const checkHeight = useCallback(
    debounce((value) => {
      if (value < 45) {
        setError("height", {
          message: `${t("minimum")} 45`,
        });
      }
      if (value > 250) {
        setError("height", {
          message: `${t("maxval")} 250`,
        });
      }
    }, 1500)
  );

  const setHeightValue = (e) => {
    const value = parseInt(e.target.value, 10);

    checkHeight(value);
    if (value >= 45 && value <= 250) {
      clearErrors("height");
      setHeight(value);
    }
    if (!value) {
      clearErrors("height");
      setHeight(0);
    }
  };

  const checkWeight = useCallback(
    debounce((value) => {
      if (value < 45) {
        setError("weight", {
          message: `${t("minimum")} 45`,
        });
      }
      if (value > 250) {
        setError("weight", {
          message: `${t("maxval")} 250`,
        });
      }
    }, 1500)
  );

  const [langAi, setLangAi] = useState(lang);
  const [selectedLangsAi, setSelectedLangsAi] = useState(() => {
    return map(descValue, (value, key) => {
      if (!value?.description && key !== langAi) {
        return key;
      }
      return null;
    });
  });

  const [isDescLoad, setIsDescLoad] = useState(false);
  
  const [descValueLength, setDescValueLength] = useState(
    !!formData.description[lang]?.description ? formData.description[lang].description.length : 0
  );

  const setWeightValue = (e) => {
    const value = parseInt(e.target.value, 10);

    checkWeight(value);
    if (value >= 45 && value <= 250) {
      clearErrors("weight");
      setWeight(value);
    }
    if (!value) {
      clearErrors("weight");
      setWeight(0);
    }
  };

  const backStep = () => {
    changeStep((prev) => prev - 1);
    rootElem.scrollIntoView({
      behavior: "smooth",
    });
  };

  const descChange = (e) => {
    const value = e.target.value;
    const limitedValue = value.substring(0, 400);
    setDescValueLength(limitedValue.length);
    let newDescValue = { ...descValue };
    if (!newDescValue[langAi]) {
      newDescValue[langAi] = { description: "" };
    }
    newDescValue[langAi].description = limitedValue;
    setDescValue(newDescValue);
  };

  const generateText = (data) => {
    const fetchData = async () => {
      setIsDescLoad(true);

      const route = `user/profile/${formData?.slug}`;

      const method = "PUT";
      const payload = {
        ...(!!data.height && { height: data.height }),
        ...(!!data.weight && { weight: data.weight }),
        ...(!!data.perfume && { perfume: data.perfume }),
        ...(!!breastType && { breast: breastType }),

        ...((gender === "female" || gender === "trans") &&
          breastCategory !== null && {
            silicone: breastCategory === "sil" ? true : false,
          }),
        ...(!!data.penis && { penis_size: data.penis }),
        ...(!!hairType && { hair: hairType }),
        ...(!!hairLength && { hair_length: hairLength }),
        ...(!!hairIntim && { intim_hairstyle: hairIntim }),
        ...(!!eyesColor && { eyes: eyesColor }),
        have_glasses: eyesType.includes("glasses") ? 1 : 0,
        have_lenses: eyesType.includes("lenses") ? 1 : 0,
        ...(!!lips && { lips: lips }),
        ...(!!orientation && { orientation: orientation }),
        ...(!!gender && { gender: gender }),
        ...(!!tatoo && { tatoo: tatoo }),
        ...(!!piercing && { piercing: piercing }),
        ...(!!smoking && { smoking: smoking }),
        ...(!!shoulders && { shoulders: String(shoulders) }),
        ...(!!hips && { hips: String(hips) }),
        ...(!!waist && { waist: String(waist) }),
      };

      const resultPut = await makeRequest({ route, method, payload });

      if (resultPut.message === "updated") {
        try {
          const route = `user/${formData.slug}/get-description/${langAi}`;

          const method = "GET";

          const result = await makeRequest({ route, method });

          if (result) {
            const value = result["description"];
            const limitedValue = value.substring(0, 400);
            setDescValueLength(limitedValue.length);
            let newDescValue = { ...descValue };
            if (!newDescValue[langAi]) {
              newDescValue[langAi] = { description: "" };
            }
            newDescValue[langAi].description = limitedValue;
            setDescValue(newDescValue);
          }
        } catch (error) {}

        setIsDescLoad(false);
      }
    };

    fetchData();
  };

  const sortedGender = options.gender.sort((a, b) => {
    if (a.value === formData?.gender) {
      return -1;
    }
    if (b.value === formData?.gender) {
      return 1;
    }
    return 0;
  });

  const windowWidth = window.innerWidth;

  const isTablet = windowWidth < 1199.98;
  const isMobile = windowWidth < 767.98;

  const onSubmit = (data) => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/profile/${formData?.slug}`;

        const method = "PUT";
        const payload = {
          isFull: true,
          ...(!!data.height && { height: data.height }),
          ...(!!data.weight && { weight: data.weight }),
          ...(!!data.perfume && { perfume: data.perfume }),
          ...(!!breastType && { breast: breastType }),

          ...((gender === "female" || gender === "trans") &&
            breastCategory !== null && {
              silicone: breastCategory === "sil",
            }),
          ...(!!data.penis && { penis_size: data.penis }),
          ...(!!hairType && { hair: hairType }),
          ...(!!hairLength && { hair_length: hairLength }),
          ...(!!hairIntim && { intim_hairstyle: hairIntim }),
          ...(!!eyesColor && { eyes: eyesColor }),
          ...{ ...descValue },
          description_translate_to: {
            ...selectedLangsAi.filter(
              (lang) => lang
            ) || [],
          },
          have_glasses: eyesType.includes("glasses") ? 1 : 0,
          have_lenses: eyesType.includes("lenses") ? 1 : 0,
          ...(!!lips && { lips: lips }),
          ...(!!orientation && { orientation: orientation }),
          ...(!!gender && { gender: gender }),
          ...(!!tatoo && { tatoo: tatoo }),
          ...(!!piercing && { piercing: piercing }),
          ...(!!smoking && { smoking: smoking }),
          ...(!!shoulders && { shoulders: String(shoulders) }),
          ...(!!hips && { hips: String(hips) }),
          ...(!!waist && { waist: String(waist) }),
        };
        const result = await makeRequest({ route, method, payload });
        if (result.status) {
          setIsLoading(false);
          !!setOpen && setOpen(false);
          !!setAvailableSteps && setAvailableSteps((prev) => [...prev, 4]);
          !!changeStep && changeStep((prev) => prev + 1);
          setFormData(() => ({
            ...result.profile,
          }));
          !!setFilled && setFilled(result.profile.completion);

          !!rootElem &&
            rootElem.scrollIntoView({
              behavior: "smooth",
            });
        }
      } catch (error) {
        console.log(error);
        showToast({
          message: t("oops"),
          variant: "error",
        });
        setIsLoading(false);
      }
    };

    token && fetchData();
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      breast_size: breastType,
      silicone: breastCategory === "sil",
      hair: hairType,
      hair_length: hairLength,
      intim_hairstyle: hairIntim,
      eyes: eyesColor,
      have_glasses: eyesType.includes("glasses"),
      have_lenses: eyesType.includes("lenses"),
      lips,
      tatoo,
      piercing,
      smoking,
      shoulders,
      hips,
      waist,
      orientation,
      gender,
      description: descValue,
      height,
      weight,
      perfume,
      penis_size: penis,
    }));
  }, [
    breastType,
    breastCategory,
    hairType,
    hairLength,
    hairIntim,
    eyesColor,
    eyesType,
    lips,
    tatoo,
    piercing,
    smoking,
    shoulders,
    hips,
    waist,
    orientation,
    gender,
    descValue,
    setFormData,
    height,
    weight,
    perfume,
    penis,
  ]);

  const handleClose = () => {
    setOpen(false)
  }

  if (!!setOpen) {
    return (
      <>
        {isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}
        <Popup
          open={open}
          setOpen={handleClose}
          onSubmit={handleSubmit(onSubmit)}
          clazz={'popup-form__body__edit'}
        >
            <h2 className="mt-48">{t("params")}</h2>
            <MainPart
              langAi={langAi}
              setLangAi={setLangAi}
              selectedLangsAi={selectedLangsAi}
              setSelectedLangsAi={setSelectedLangsAi}
              setOpen={setOpen}
              isMobile={isMobile}
              formData={formData}
              setOrientation={setOrientation}
              gender={gender}
              data={data}
              setGender={setGender}
              sortedGender={sortedGender}
              options={options}
              register={register}
              errors={errors}
              setHeightValue={setHeightValue}
              setWeightValue={setWeightValue}
              breastCategory={breastCategory}
              setBreastCategory={setBreastCategory}
              setBreastType={setBreastType}
              breastType={breastType}
              isTablet={isTablet}
              setHairLength={setHairLength}
              setHairType={setHairType}
              hairType={hairType}
              setHairIntim={setHairIntim}
              hairIntim={hairIntim}
              eyesType={eyesType}
              setEyesType={setEyesType}
              setEyesColor={setEyesColor}
              eyesColor={eyesColor}
              setLips={setLips}
              lips={lips}
              setTatoo={setTatoo}
              tatoo={tatoo}
              setPiercing={setPiercing}
              piercing={piercing}
              setSmoking={setSmoking}
              smoking={smoking}
              theme={theme}
              setShoulders={setShoulders}
              shoulders={shoulders}
              setWaist={setWaist}
              waist={waist}
              setHips={setHips}
              hips={hips}
              isDescLoad={isDescLoad}
              descValue={descValue}
              descChange={descChange}
              descValueLength={descValueLength}
              handleSubmit={handleSubmit}
              generateText={generateText}
              setPerfume={setPerfume}
              setPenis={setPenis}
            />
            <PopupButtons isLoading={isLoading} setOpen={setOpen} />
        </Popup>
      </>
    );
  }

  return (
    <>
      <h2>{t("params")}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onClick={(e) => e.stopPropagation()}
      >
        <MainPart
          langAi={langAi}
          setLangAi={setLangAi}
          selectedLangsAi={selectedLangsAi}
          setSelectedLangsAi={setSelectedLangsAi}
          setOpen={setOpen}
          isMobile={isMobile}
          formData={formData}
          setOrientation={setOrientation}
          gender={gender}
          data={data}
          setGender={setGender}
          sortedGender={sortedGender}
          options={options}
          register={register}
          errors={errors}
          setHeightValue={setHeightValue}
          setWeightValue={setWeightValue}
          breastCategory={breastCategory}
          setBreastCategory={setBreastCategory}
          setBreastType={setBreastType}
          breastType={breastType}
          isTablet={isTablet}
          setHairLength={setHairLength}
          setHairType={setHairType}
          hairType={hairType}
          setHairIntim={setHairIntim}
          hairIntim={hairIntim}
          eyesType={eyesType}
          setEyesType={setEyesType}
          setEyesColor={setEyesColor}
          eyesColor={eyesColor}
          setLips={setLips}
          lips={lips}
          setTatoo={setTatoo}
          tatoo={tatoo}
          setPiercing={setPiercing}
          piercing={piercing}
          setSmoking={setSmoking}
          smoking={smoking}
          theme={theme}
          setShoulders={setShoulders}
          shoulders={shoulders}
          setWaist={setWaist}
          waist={waist}
          setHips={setHips}
          hips={hips}
          isDescLoad={isDescLoad}
          descValue={descValue}
          descChange={descChange}
          descValueLength={descValueLength}
          handleSubmit={handleSubmit}
          generateText={generateText}
          setPerfume={setPerfume}
          setPenis={setPenis}
        />
        <DefaultButtons backStep={backStep} isLoading={isLoading} />
      </form>
    </>
  );
};

export default AppearanceGlobal;
