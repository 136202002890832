import { useDispatch, useSelector } from "react-redux";
import {useEffect, useRef} from "react"
import {
  getAdFragment,
  getUserCity,
  getUserCountry,
  getUserLang,
  getUserType,
  themeMode,
  getUserCurrentCity,
  getUserLoadStatus,
  getUserEmail,
  getUserPhone,
  getUserTg,
  getUserWa,
  getUserID,
  getUserRegion,
  getUserTariff,
} from "@/stores/slices/userSlice";
import useEffectWindowSize from "../hooks/useEffectWindowSize";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import { wordEndings } from "@/helper/wordEndings";
import {
  setAuthorizationStatus,
  setAviableForUsersStatus,
} from "@/stores/slices/popupSlice";
import { getUserFilterStatus } from "@/stores/slices/filterSlice";
import { getIsMainPage } from "@/stores/slices/modelsState";
import { getIsAgencyPage } from "@/stores/slices/agencySlice";
import {getUserBalance, getUserTariffSlug, getUserHold, getEmailVerifyAt, getUserElitePrice} from "@/stores/slices/userSlice";
import {getIsLkPage} from "@/stores/slices/userSlice";
import {format, fromUnixTime, differenceInMinutes, differenceInHours} from "date-fns";

// здесь собраны самые частоиспользуемые команды, чтобы не дублировать их каждый раз
const useLadyService = () => {
  const { t } = useTranslation("translation");

  const token = localStorage.getItem("user");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // получить тип пользователя
  const userType = useSelector(getUserType);

  const userTypeAgency = userType === 'agency'
  const userTypePhoto = userType === 'photograph'
  const userTypeIndi = userType === 'indi'
  const userTypeCustomer = userType === 'customer'
  const userTypeAdmin = userType === 'admin'

  const isMainPage = useSelector(getIsMainPage);
  const isAgencyPage = useSelector(getIsAgencyPage);

  const userCity = useSelector(getUserCurrentCity);

  const userInfoStatus = useSelector(getUserLoadStatus);
  const userFilterStatus = useSelector(getUserFilterStatus);

  const emailVerifyAt = useSelector(getEmailVerifyAt)

  const userPhone = useSelector(getUserPhone);
  const userEmail = useSelector(getUserEmail);
  const userTg = useSelector(getUserTg);
  const userWa = useSelector(getUserWa);

  const userTariff = useSelector(getUserTariff);
  const userTariffSlug = useSelector(getUserTariffSlug);
  const userTariffSilver = userTariffSlug === 'silver'
  const userTariffGold = userTariffSlug === 'gold'
  const userTariffPlatinum = userTariffSlug === 'platinum'

  const elitePrice = useSelector(getUserElitePrice);

  // тема светлая/тёмная
  const theme = useSelector(themeMode);

  // получение языка
  const lang = useSelector(getUserLang);

  // получение города
  const city = useSelector(getUserCity);

  const region = useSelector(getUserRegion);
  const userBalance = useSelector(getUserBalance);
  const userBalanceHold = useSelector(getUserHold);
  const resultBalance = userBalance - userBalanceHold 
  const adFragment = useSelector(getAdFragment);

  const userId = useSelector(getUserID);

  // получение страны
  const country = useSelector(getUserCountry);

  // медиазапрос
  const windowWidth = useEffectWindowSize();

  // форматирования даты (12.05.2024 в 14:45)
  const formattedDate = (created_at) => {
    const date = format(fromUnixTime(created_at), 'dd.MM.yyyy');
    const hours = format(fromUnixTime(created_at), 'HH:mm');

    return `${date} ${t("at")} ${hours}`
  }

  // форматирования даты (12.05.2024)
  const getDayMonthYear = (created_at) => {
    return format(fromUnixTime(created_at), 'dd.MM.yyyy');
  }


  // парсинг таймстемпа (когда модель была онлайн)
  const getTimeAgo = (last_activity) => {
    const date = fromUnixTime(last_activity);
    const now = new Date()

    const diffInMinutes =  differenceInMinutes(now, date)
    const diffInHours = differenceInHours(now, date);
    if (diffInMinutes < 15) {
      return t("online");
    } else if (diffInMinutes < 60) {
      return `${t("came")} ${t("hoursago").toLowerCase()}`;
    } else if (diffInHours < 24) {
      return `${t("came")} ${t("today").toLowerCase()}`;
    } else if (diffInHours < 48) {
      return `${t("came")} ${t("daysago").toLowerCase()}`;
    } else if (diffInHours < 72) {
      return `${t("came")} 3 ${t("days")} ${t("back").toLowerCase()}`;
    } else if (diffInHours < 96) {
      return `${t("came")} 4 ${t("days")} ${t("back").toLowerCase()}`;
    } else {
      return `${t("came")} ${t("thisweek").toLowerCase()}`;
    }
  };


  const TimeAgo = ({ timestamp, timeAgo, setTimeAgo }) => {
    const secondsSinceEpoch = parseInt(timestamp, 10);
    const date = fromUnixTime(secondsSinceEpoch);
    const now = new Date();

    const diffInMinutes = differenceInMinutes(now, date);
    const diffInHours = differenceInHours(now, date);

    const pluralize = (count, words) => {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        count % 100 > 4 && count % 100 < 20
          ? 2
          : cases[count % 10 < 5 ? count % 10 : 5]
      ];
    };

    if (diffInMinutes < 60) {
      setTimeAgo(`${diffInMinutes} ${t("minago")}`);
    } else if (diffInHours < 24) {
      setTimeAgo(
        `${diffInHours} ${pluralize(diffInHours, [
          t("hour"),
          t("hourfew"),
          t("hourmany"),
        ])} ${t("back")}`
      );
    } else {
      setTimeAgo(`${t("daysago")}`);
    }

    return timeAgo;
  };

  // из минут в часы/дни
  const minutesToDhms = (minutes) => {
    minutes = Number(minutes);
    const d = Math.floor(minutes / (60 * 24));
    const h = Math.floor((minutes % (60 * 24)) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay =
      d > 0 ? `${d} ${wordEndings(d, [t("day"), t("days"), t("days")])} ` : "";

    const hDisplay =
      h > 0
        ? `${h} ${wordEndings(h, [t("hour"), t("hourfew"), t("hourmany")])} `
        : "";

    const mDisplay = m > 0 ? `${m} ${t("min")}` : "";

    return dDisplay + hDisplay + mDisplay;
  };

  // конвертирует время из "10:00:00" в "10:00"
  const convertTime = (timeString) => {
    const timeParts = timeString.split(":");

    const hours = timeParts[0];
    const minutes = timeParts[1];

    return `${hours}:${minutes}`;
  };
  const setAvialableForUser = () => {
    dispatch(setAuthorizationStatus(true));
    dispatch(setAviableForUsersStatus(true));
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const gendersTitle = {
    male: t("man"),
    trans: t("trans"),
    couple: t("couple"),
    female: t("woman"),
  };

  const orientations = {
    homosexual: t("homo"),
    lesbian: t("lesbi"),
    bisexual: t("bi"),
    straight: t("hetero"),
  };

  const travelVariants =  {
      null: t('none'),
      "nearcity": t('nearcity'),
      "incountry": t('incountry'),
      "ineurope": t('ineurope'),
      "inworld": t('inworld'),
  }

  const operatorAccess = {
    "agencyprofileman": "lk/agency",
    "adsamanage": "lk/profiles",
    "promoandads": "lk/profiles",
    "reviewmanage": "lk/reviews",
    "partiesmanage": "lk/createparty",
    "statsaccess": "lk/statistic",
    "balancemanage": "lk/balance",
    "gotopreviews": "lk/previews",
    "bookings": "lk/reservations",
    "videochats": "lk/videochats"
  }

  const checkAccessAndRedirect = (key) => {
    if (operatorAccess[key]) {
      // Если ключ найден, перенаправляем на нужную страницу
      window.location.href = `/${operatorAccess[key]}`;
    } else {
      // Если ключ не найден, перенаправляем на главную страницу
      window.location.href = '/';
    }
  };

// Пример использования:
  const userAccessKey = "statsaccess"; // Здесь может быть любое значение для проверки
  // checkAccessAndRedirect(userAccessKey);

  // задать формат изображения (xl,xxl, и т.д)
  // размеры (по высоте):
  // xl - 900
  // l - 700
  // m - 500
  // s - 360
  // xs-200
  // xxs-60
  function setMediaSize(media, size) {
    if (!!media) {
      const res = typeof media === "string" ? media : media[0];
      return res?.replace(/\.\w+$/, `/${size}.webp`);
    }

    return null;
  }

  const capitalizeFirstLetter = (str) => {
    return str.replace(/^./, (char) => char.toUpperCase());
  }

  const scrollToTop = (top = 0, left = 0) => {
    window.scrollTo({
      top: top,
      left: left,
      behavior: "smooth",
    });
  }

  const scrollToHash = () => {
    const statusesHash = window.location.hash;
    if (statusesHash) {
      const element = document.querySelector(statusesHash);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }

  const isLk = useSelector(getIsLkPage)


  return {
    capitalizeFirstLetter,
    elitePrice,
    emailVerifyAt,
    resultBalance,
    userBalanceHold,
    userBalance,
    isMainPage,
    isAgencyPage,
    token,
    dispatch,
    navigate,
    userType,
    userTypeAgency,
    userTypePhoto,
    userTypeIndi,
    userTypeCustomer,
    userTypeAdmin,
    userId,
    userCity,
    theme,
    lang,
    city,
    region,
    country,
    getTimeAgo,
    gendersTitle,
    orientations,
    minutesToDhms,
    convertTime,
    setAvialableForUser,
    formattedDate,
    getDayMonthYear,
    TimeAgo,
    adFragment,
    windowWidth,
    setMediaSize,
    t,
    userInfoStatus,
    userFilterStatus,
    userPhone,
    userEmail,
    userTg,
    userWa,
    userTariff,
    userTariffSlug,
    userTariffSilver,
    userTariffGold,
    userTariffPlatinum,
    travelVariants,
    scrollToTop,
    scrollToHash,
    isLk,
    usePrevious,
  };
};

export default useLadyService;
