import {Loader} from "@/components/ui";
import "./connectPromotionAd.scss";

import {useState, useEffect} from "react";
import {makeRequest} from "@/services/makeRequest";



import {viewList} from "../../pages/accounts/model/profiles/card/components/upAd/viewList";
import {Icon} from "../../ui";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";

const PromotionTablePopup = (props) => {
    const {
        city,
        slug,
        position,
        setPrice,
        setPosition,
        setPromotion,
        open,
        setOpen,
        name,
        positionPrice,
        city_id,
        dynamicTop,
        setIsOpenUp,
        setSelectPosition,
        setSelectPrice,
        placesData,
        setPlacesData
    } = props;


    const {t} = useLadyService();

    const [currentPosition, setCurrentPosition] = useState(position);
    const [isLoading, setIsLoading] = useState(true);
		const [minPrice, setMinPrice] = useState(false);

    


    const handleOpenUp = ({place, price}) => {
        setSelectPrice(price);
        setSelectPosition(place);
        setIsOpenUp(true);
    };

    console.log('data: ', props)

    const postCity = t("incity", {City: city ?? ""})

    const calculatePercentageValue = ({currentPosition, position}) => {
        if (!currentPosition || !placesData.length) {
            return null;
        } else {
            const mt = viewList[currentPosition - 1]["view"];

            const mp = viewList[position - 1]["view"];

            const res = Math.floor(((mp - mt) / mt) * 100);

            return res;
        }
    };

    const calculatePercentageWidth = (max, current) => {
        return (current / max) * 100;
    };
 
    const calcFirst = calculatePercentageValue({
        currentPosition,
        position: 1,
    });

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const route = `places/${slug}`;

                const method = "GET";

                const {positions} = await makeRequest({route, method});
                
                const {available, current} = positions;
                
                let prevPrice = 0
                let availableRes = available.map((i, index) => {
                    const price = index + 1 >= current ? i : i + 1
                    let place = "fill"
                    if (prevPrice === price) {
                        place = "empty"
                    }
                    prevPrice = price
                    return {place, price};
                });
                availableRes = [...availableRes, {place: "free", price: 1}]
                console.log('fetch: a-', availableRes, 'c-', current);
                setPlacesData(availableRes);

                setCurrentPosition(current);
                setIsLoading(false);
            } catch (error) {
            }
        };

        fetchData();
    }, [slug]);


    

    return (
        <>
            <Popup
                open={open}
                setOpen={setOpen}
                container={'div'}
            >
                {isLoading ? (
                    <Loader/>
                ) : (
                    <>
                     
                        <h2 className="mt-48 mb-32 text-center">
                            {t("currentprices")}
                            {' '}
                            {postCity}
                        </h2>
                        <table>
                            <thead className="up-table__header">
                                <th>{t("place")}</th>
                                <th>{t("price")}</th>
                                <th>{t("plustoviews")}<span className="color-green">*</span></th>
                            </thead>
                            <tbody>
                                {placesData.map((item, index) => {
                                    const calc = calculatePercentageValue({
                                        currentPosition,
                                        position: index + 1,
                                    });

                                    const width = calculatePercentageWidth(calcFirst, calc);

																		if(!minPrice){
																			setMinPrice(item.price)
																		}
																		else if(minPrice > item.price){
																			setMinPrice(item.price)
																		}

                                    switch (item.place){
                                        case 'fill':{
                                            return(
                                            <tr
																							id={'fill'+index}
                                              key={index}
																							onClick={() =>
																								handleOpenUp({place: index + 1, price: item.price})
																							}
																							className={
																							index + 1 === currentPosition
																								? "up-table__row up-table__row__zero"
																								: item.place === "free" && index + 1 > currentPosition
																										? "up-table__row up-table__row__last"
																										: "up-table__row"
																							}
                                            >
																							<td>{index + 1}</td>
																							<td>
																								{item.price}
																								<Icon
																									title={t("EroCoins")}
																									clazz={`color-main ml-4`}
																									spritePath={"erocoin"}
																									size={"xs"}
																								/>
																							</td>

																							{index + 1 === currentPosition ? (
																									<td>{t("currentpos")}</td>
																							) : (
																									<td
																											style={{"--percent": `${width > 0 ? width : 0}%`}}
																									>
																							{calc > 0 ? ` +${calc} %` : ` ${calc} %`}
																							</td>
																							)}
                                            </tr>
                                        )
                                        }
                                        case 'empty':{
																					if(placesData[index - 1] && placesData[index - 1].place !== "empty")
                                            return(<tr className="up-table__row up-table__row empty">
																							<td>&mdash;</td>
																							<td>&mdash;</td>
																							<td>&mdash;</td>
																						</tr>)
                                        }
                                        case 'free':{
																					if(item.price == minPrice)
                                            return(
																							<tr
																								key={index}
                                            		className={"up-table__row up-table__row free"}
                                            		onClick={() =>
                                            		    handleOpenUp({place: index + 1, price: item.price})
                                            		}
																							>
																								<td>{index + 1}</td>
																								<td>
																									{item.price}
																									<Icon
																											title={t("EroCoins")}
																											clazz={`color-main ml-4`}
																											spritePath={"erocoin"}
																											size={"xs"}
																									/>
																								</td>
																								<td style={{"--percent": `${width > 0 ? width : 0}%`}}>
																									{calc > 0 ? ` +${calc} %` : ` ${calc} %`}
																								</td>
																							</tr>)
                                        }
                                        default: {
                                            return (<></>)
                                        }
                                    }
                                })}
																
																{!positionPrice && (
																	<>
																		<tr className="up-table__row up-table__row empty">
																			<td>&mdash;</td>
																			<td>&mdash;</td>
																			<td>&mdash;</td>
																		</tr>
	
		                                <tr className="up-table__row up-table__row__zero__last">
		                                    <td>{currentPosition}</td>
		
		                                    <td>&mdash;</td>
		
		                                    <td>{t("currentpos")}</td>
		                                </tr>
	                                  
																	</>
                                )}
                            </tbody>
                        </table>

												<span className="p2 text-center ml-8 mr-8"><span className="color-green">* </span>{t("nearvalue")}</span>
                    </>
                )}
            </Popup>

            
        </>
    );
};

export default PromotionTablePopup;
