import { useSelector } from "react-redux";
import {
  getAppealStatus,
  setAppealStatus,
  setSuccessAppealStatus,
} from "@/stores/slices/popupSlice";
import { InputInLabel } from "@/components/ui";
import { useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import { Popup } from "../index";
import useLadyService from "@/services/LadyService";
import { LoadingButton } from "../../ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useState } from "react";

const Appeal = ({name, slug}) => {

  
  
  const { t, dispatch } = useLadyService();
  const AppealValue = useSelector(getAppealStatus);
  const formSchema = object().shape({
    body: string().required(`${t("required")}`),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);
    const fetchData = async () => {
      try {
        const route = `tickets`;
        const method = "POST";
        const payload = {
          message : data.body,
          subject : t('сomplaintmodel') + " " + name,
          profile : slug,
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          showToast({
            message: t("successreview"),
            subMessage: t("willpublish"),
            variant: "success",
          });
          reset();
          dispatch(setSuccessAppealStatus(true));
          dispatch(setAppealStatus(false));
        }

        setLoading(false);
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        setLoading(false);
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={!!AppealValue}
      setOpen={() => dispatch(setAppealStatus(false))}
      id={"addAppeal"}
      method={"POST"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className="mt-48">{t("complain")}</h2>
      <p className={"color-600 p2 mb-0"}>{t("complaintreview")}</p>

      <div className="popup-form__inner">
        <div className={"popup-form__inner text-center gap-4"}>
          <p className={"color-600 fz-13 mb-0"}>{t("modelname")}</p>
          <span className={"color-green p1"}>{name}</span>
        </div>

        <InputInLabel
          register={{ ...register("body") }}
          type={"textarea"}
          id={"appealText"}
          placeholder={t("incidentoccur")}
          clazz={`required ${errors.body ? "error" : ""}`}
        >
          {t("complaintreason")}
        </InputInLabel>

        <LoadingButton
          buttonType="submit"
          isLoading={loading}
          size={"l"}
          clazz={"button--green"}
        >
          {t("send")}
        </LoadingButton>
      </div>
    </Popup>
  );
};

export default Appeal;
