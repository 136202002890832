import {LoadingButton, Button} from "@/components/ui";
import useLadyService from "@/services/LadyService";

const PopupButtons = ({ setOpen, isGlobalLoading, validateData }) => {
  const { t } = useLadyService();
  return (
    <div className="create-form__buttons">
      <Button
        size={"l"}
        square={false}
        clazz="button--secondary min-w-160"
        onClick={() => setOpen(false)}
      >
        {t("cancel")}
      </Button>
      <LoadingButton
        isLoading={isGlobalLoading}
        onClick={validateData}
        buttonType={"submit"}
        size={"l"}
        square={false}
        clazz="button--green min-w-160"
      >
        {t("save")}
      </LoadingButton>
    </div>
  );
};

export default PopupButtons