import Navigation from "../navigation/Navigation";
import {useDispatch, useSelector} from "react-redux";
import {getStories} from "@/stores/slices/modelsState";
import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import useLadyService from "@/services/LadyService";
import {setVideoCallRoom} from "@/stores/slices/videoCallSlice";
import {getProfilesCount} from "@/stores/slices/mapModelSlice";
import useTitle from "@/hooks/useTitle";
import {getUserCurrentCity, getUserRegion} from "@/stores/slices/userSlice";
import {getTitle} from "@/stores/slices/titleSlice";
import {Helmet} from "react-helmet";
import {getCountrySlug} from "@/stores/slices/userSlice";
import BestCityAgencySlider from "../sliders/bestCityAgencySlider/BestCityAgencySlider";
import GirlMap from "../girlMap/GirlMap";
import AboutService from "../aboutService/AboutService";
import BenefitsBlock from "../benefitsBlock/BenefitsBlock";
import StoriesComponent from "../stories/StoriesComponent";
import Selection from "@/components/pages/selection/Selection";
// const BestCityAgencySlider = lazy(() => import("../sliders/bestCityAgencySlider/BestCityAgencySlider"));
// const GirlMap = lazy(() => import("../girlMap/GirlMap"));
// const AboutService = lazy(() => import("../aboutService/AboutService"));
// const BenefitsBlock = lazy(() => import("../benefitsBlock/BenefitsBlock"));
// const StoriesComponent = lazy(() => import("../stories/StoriesComponent"));
// const Selection = lazy(() => import("@/components/pages/selection/Selection"))

const MainPage = () => {
    const dispatch = useDispatch();
    const {t, userCity, navigate, lang} = useLadyService();
    const {room, uuid} = useParams();
    const stories = useSelector(getStories);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const profilesCountValue = useSelector(getProfilesCount);
    const currentCity = useSelector(getUserCurrentCity);
    const title = useSelector(getTitle);
    const region = useSelector(getUserRegion);
    const [filterData, setFilterData] = useState([])
    const [onMap, setOnMap] = useState(true)

    useEffect(() => {
        if (room) {
            dispatch(setVideoCallRoom({room_id: room}));
        }
    }, [dispatch, room]);

    // setFilter by uuid


    const countrySlug = useSelector(getCountrySlug)
    let postTitle = t("title", {City: currentCity.title ? currentCity.title : "", CountryCode: 'cz'});
    let postDesc = t("description", {City: currentCity.title, Country: region})
    if (countrySlug) {
        postTitle = t("titlecountry", {Country: currentCity.title, CountryCode: 'cz'});
        postDesc = t("descriptioncountry", {Country: currentCity.title})
    }
    const titleString = !!title.length
        ? title
            .filter((item) => !!item)
            .map((item) => t(item))
            .join(", ")
        : "";

    const titleParams = titleString
        ? t('fastFiltersTitle', {Filters: titleString, City: currentCity.title})
        : postTitle;

    if (titleString) {
        postDesc = t('fastFiltersDesc', {Filters: titleString, City: currentCity.title})
    }

    useTitle(titleParams)
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');

    useEffect(()=>{
        console.log('cs: ', countrySlug)
    }, [countrySlug])

    return (
        <>
            <main>
                <Helmet>
                    <meta name="description" content={postDesc}/>
                </Helmet>
                <Navigation setIsDataEmpty={setIsDataEmpty} isDataEmpty={isDataEmpty} mainPage={true} setOnMap={setOnMap}/>
                {/*<NewGirlsSlider />*/}
                {((!isDataEmpty && !!profilesCountValue) || isJsdom) && onMap ? (<GirlMap/>) : null}
                <BestCityAgencySlider/>
                {(countrySlug) && <AboutService/>}
                {(countrySlug) && <BenefitsBlock/>}
                {(!countrySlug) && <Selection isPage={false}/>}
            </main>

            {!!stories.length && (
                <StoriesComponent
                    favoriteButton
                    descriptionText
                    profileLink
                    stories={stories}
                />
            )}
        </>
    );
};

export default MainPage;
