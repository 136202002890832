import React from "react";
import {Button, Icon} from "../../../../ui";


const EditBlock = ({ title, mb, clazz, titleType = 'h2', size = 'xs', onClick }) => {

  let btn = () => (
    <Button
      onClick={onClick}
      clazz={"button_outline--green button-icon"}
    >
      <Icon spritePath={"edit-2"} size={"s"} />
    </Button>
  )

  let classNames = `d-flex gap-12 mt-0${mb ? ` mb-${mb}` : ""}${clazz ? ` ${clazz}` : ''}`

  return (
      !!title ?
        titleType === 'h2' ?
        <h2 className={classNames}>
          {title}
          {btn()}
        </h2>
          :
          <h3 className={classNames}>
            {title}
            {btn()}
          </h3>

      : btn()
  );
};

export default EditBlock;