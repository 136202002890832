import { Icon } from "@/components/ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import EditBlock from "../editBlock/EditBlock";

const ModelExtras = ({ props, edit, setIsOpenEditMain}) => {
  const {
    gf,
    ready_travel,
    is_escort,
    porn_star,
  } = props;

  const { travelVariants, lang } = useLadyService();

  const { t } = useTranslation("translation");

  return !!gf.length || !!porn_star || !!ready_travel || !!is_escort ? (
    <div className="model-extras">
      {!!gf.length && 
       gf?.map((model, index) => { return (
        
          <Template translateKey={t("girlfriend")} icon={"girlfriend"}>
            <Link
              key={index}
              target={"_blank"}
              to={`/${lang}/profile/${model.slug}`}
              className={"color-green hover-line p2"}
            >
              {model.slug}
            </Link>
          </Template>
        
        )}
      )}

      {!!porn_star?.length && (
        
          <Template translateKey={t("pornstar")} icon={"star"}>
            <Link
              target={"_blank"}
              to={porn_star?.website_link ? `//${porn_star.website_link}` : ""}
              className={"color-green hover-line p2"}
            >
               {porn_star.website_link}
            </Link>
          </Template>
       
      )}

      {!!ready_travel && (
        <Template translateKey={t("travel")} icon={"travel"}>
          <span className={"p2 color-600"}>{travelVariants[ready_travel]}</span>
        </Template>
      )}

      {!!is_escort && (
        <Template translateKey={t("accompaniment")} icon={"escort"}>
          <span className={"p2 color-600"}>{t("nosex")}</span>
        </Template>
      )}

      {!!edit && (
        <EditBlock
          onClick={() => setIsOpenEditMain(true)}
          size={'l'}
        />
      )}
    </div>
  ) : null;
};

const Template = ({ translateKey, icon, children }) => (
  <div title={translateKey} className={"model-extras__item"}>
    <Icon spritePath={icon} />
    <span className={"model-extras__text"}>
      {translateKey}
      {children}
    </span>
  </div>
);

export default ModelExtras;
