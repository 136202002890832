import { useDispatch, useSelector } from "react-redux";
import {
  getSuccessAddReviewStatus,
  getSuccessAppealStatus,
  setAddReviewStatus,
  setSuccessAddReviewStatus,
  setSuccessAppealStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useTranslation } from "react-i18next";
import {Popup} from "../index";

const SuccessReviewMassage = () => {
  const dispatch = useDispatch();
  const SuccessAppealValue = useSelector(getSuccessAppealStatus);
  const { t } = useTranslation("translation");

  return (
    <Popup
      open={!!SuccessAppealValue}
      setOpen={() => dispatch(setSuccessAppealStatus(false))}
      container={'div'}
      clazz={'gap-16'}
    >
        <h3 className="mt-48">{t("successreview")}</h3>
        <p className={"color-600 p2"}>
          {t("complaintreview")}
        </p>
    </Popup>
  );
};

export default SuccessReviewMassage;
