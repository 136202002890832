import { Button } from "@/components/ui";

import { useState } from "react";
import { makeRequest } from "@/services/makeRequest";

import statusElite from "../../../assets/img/status/elite-mob.svg";

import showToast from "../../toast/Toast";

import "./makeEliteAd.scss";
import { Popup } from "../index";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";

const MakeEliteAdOnePopup = (props) => {
  const {
    setOpen,
    id,
    main_photo,
    name,
    setElite,
    slug,
    profiles = [],
  } = props;

  const { t, elitePrice } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/elite/set`;

        const method = "POST";
        const payload = {
          profiles: [id],
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          if(result?.result == true){
            setElite(true);
          }
          // else{
          //   showToast({
          //     message: t("oops"),
          //     variant: "error",
          //   });
          // }
          setIsLoading(false);
          onClose();
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      {...props}
      setOpen={onClose}
      isLoading={isLoading}
      container={"div"}
    >
      <h2 className="mt-48">{t("makead")}</h2>

      <picture className={"text-center mb-16"}>
        <source srcSet={statusElite} media="(min-width: 767.98px)" />
        <img src={statusElite} alt="" />
      </picture>

      <span className={"p1 text-center mb-32"}>
        {t("adelitebonus")}{" "}
        <span className={"color-green"}>
          (+50% {t("toviews")} {t("and")} +25% {t("toorders")}){" "}
        </span>
      </span>

      {!!profiles.length ? (
        <div className="agency-block__content make-elite--agency">
          {profiles.map((item) => {
            return (
              <Link
                target="_blank"
                to={`/profile/${item.slug}`}
                className="elite-block hover-line"
              >
                <img src={item.main_photo} alt={item.name} title={item.name} />

                <span className="elite-block__link-to">{item.name}</span>
              </Link>
            );
          })}
        </div>
      ) : (
        <Link
          target="_blank"
          to={`/profile/${slug}`}
          className="fd-column gap-16 m-auto br-4 hover-line"
        >
          <img className="w-100p br-4" src={main_photo} alt={name} title={name} />

          <span className="elite-block__link-to hover-line text-center">{name}</span>
        </Link>
      )}

      <p className="p1 color-main text-center mt-32 mb-16">
        {elitePrice.oldPrice && (
          <span className="fz-24 currency--erocoin old-price">
            {!!profiles.length ? elitePrice.oldPrice * profiles.length : elitePrice.oldPrice}
          </span>
        )}{" "}
        <span className="fz-24 currency--erocoin">
          {!!profiles.length ? elitePrice.currentPrice * profiles.length : elitePrice.currentPrice}
        </span>{" "}
        / {t("month")}
      </p>

      <Button
        size={"l"}
        onClick={onSubmit}
        clazz={"button--green justify-center mt-auto"}
      >
        {t("confirm")}
      </Button>
    </Popup>
  );
};

export default MakeEliteAdOnePopup;
