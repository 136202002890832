import useLadyService from "@/services/LadyService";
import { Button, Checkbox, Icon, InputInLabel, Loader } from "../../ui";
import { Popup } from "../index";
import { useSelector } from "react-redux";
import {
  getChangePhotoStatus,
  setChangePhoto,
} from "@/stores/slices/popupSlice";
import { getIndiMediaToChange } from "@/stores/slices/lkIndiSlice";
import { useState } from "react";
import FilerobotImageEditor, { TABS } from "react-filerobot-image-editor";


const ChangePhoto = () => {
  const { t,  dispatch, setMediaSize } = useLadyService();

  const media = useSelector(getIndiMediaToChange);
  const open = useSelector(getChangePhotoStatus);

  const [loading, setLoading] = useState(true);
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  return (
    <Popup
      open={open}
      setOpen={() => dispatch(setChangePhoto(false))}
      clazz={"gap-16"}
    >
      <h2 className="mt-48">{"PhotoShop"}</h2>
      {!loading && isImgEditorShown && (
        <FilerobotImageEditor
          source={setMediaSize(media, "l")}
          onSave={(editedImageObject, designState) =>
            console.log("saved", editedImageObject, designState)
          }
          onClose={closeImgEditor}
          annotationsCommon={{
            fill: "#ff0000",
          }}
          Text={{ text: "Filerobot..." }}
          Rotate={{ angle: 90, componentType: "slider" }}
          Crop={{
            presetsItems: [
              {
                titleKey: "classicTv",
                descriptionKey: "4:3",
                ratio: 4 / 3,
              },
              {
                titleKey: "cinemascope",
                descriptionKey: "21:9",
                ratio: 21 / 9,
              },
            ],
            presetsFolders: [
              {
                titleKey: "socialMedia",
                // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                groups: [
                  {
                    titleKey: "facebook",
                    items: [
                      {
                        titleKey: "profile",
                        width: 180,
                        height: 180,
                        descriptionKey: "fbProfileSize",
                      },
                      {
                        titleKey: "coverPhoto",
                        width: 820,
                        height: 312,
                        descriptionKey: "fbCoverPhotoSize",
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          tabsIds={[TABS.ADJUST]}
          defaultTabId={TABS.ADJUST}
        />
      )}
      {loading && <Loader />}
      <div className="d-flex gap-12">
        <Button
          size={"l"}
          clazz={"button--secondary justify-center w-100"}
          onClick={() => dispatch(setChangePhoto(false))}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l"}
          buttonType={"submit"}
          clazz={"button--green justify-center w-100"}
        >
          {t("confirm")}
        </Button>
      </div>
    </Popup>
  );
};

export default ChangePhoto;
