import React, {useEffect, useState} from 'react';
import useLadyService from "../../../services/LadyService";
import './switch.scss';

const Switch = ({name, clazz, onChange, value, disabled}) => {
    const {t} = useLadyService();
    const [switchValue, setSwitchValue] = useState(value === 'null' ? 'any' : value ? 'yes' : 'no' );
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setSwitchValue(selectedValue);
        if (onChange) {
            onChange(selectedValue);
        }
    };

    return (
        <div className={`switch${clazz ? ` ${clazz}` : ''}${disabled ? '_disabled' : ''}`}>
            <input
                id={`${name}-y`}
                name={name}
                type="radio"
                value="yes"
                checked={switchValue === 'yes'}
                className="switch-input switch-input-y"
                onChange={handleChange}
            />
            <label htmlFor={`${name}-y`} className="switch-label switch-label-y">{t('yes')}</label>

            <input
                id={`${name}-i`}
                name={name}
                type="radio"
                value="any"
                checked={switchValue === 'any'}
                className="switch-input switch-input-a"
                onChange={handleChange}
                defaultChecked={switchValue === 'any'}
            />
            <label htmlFor={`${name}-i`} className="switch-label switch-label-i">Any</label>

            <input
                id={`${name}-n`}
                name={name}
                type="radio"
                value="no"
                checked={switchValue === 'no'}
                className="switch-input switch-input-n"
                onChange={handleChange}
            />
            <label htmlFor={`${name}-n`} className="switch-label switch-label-n">{t('no')}</label>

            <span className="switch-selector"></span>
        </div>
    );
};

export default Switch;
