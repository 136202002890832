import example1 from "@/assets/img/examplePhotos/verExample.svg";
import { Button, Icon } from "@/components/ui";

import { useEffect, useState } from "react";

import "./verAds.scss";
import ExampleImage from "../exampleImage/ExampleImage";
import useLadyService from "@/services/LadyService";
import { FastVer } from "@/components/popups";
import UploadImage from "../uploadImage/UploadImage";
import { setVerStatus } from "@/stores/slices/lkIndiSlice";
import PhotoUploadSuccess from "../photoUploadSuccess/PhotoUploadSuccess";

const VerAds = ({
  profiles,
  verified_at,
  telegram,
  whatsapp,
  data: dataInfo = {},
  isCreatePage = false,
  isVerificationExist,
  setIsVerificationExist,
  isBoosted,
  setIsBoosted
}) => {
  const { verify_photo = {} } = dataInfo;
  

  const data = verify_photo ? verify_photo.data || {} : {};

  const { link = null, moderated } = data;
  const { t, dispatch, windowWidth } = useLadyService();

  const [open, setOpen] = useState(false);
  const [isVideoRequest, setIsVideoRequest] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const isMobile = windowWidth < 767.98;

  const [image, setImage] = useState(link);
  
  const [removeLink, setRemoveLink] = useState(link);

  useEffect(() => {
    if (image) {
      dispatch(setVerStatus(true));
    } else {
      dispatch(setVerStatus(false));
    }
  }, [image]);

  useEffect(() => {
    if (!verify_photo?.data?.link) {
      setImage("");
      dispatch(setVerStatus(false));
    }
  }, [verify_photo]);

  useEffect(() => {
    if(link) {
      setImage(link)
      setRemoveLink(link)
    }
  }, [link])


  return (
    <>
      <div id={"verification"} className={`${isMobile ? "" : "verads control"}`}>
        <div className="verads__title mt-16">
          <div className="d-flex fd-column">
            <b className="title__h3">{t("idphoto")}</b>
            <span>{"(" + t("matchmedia") + ")"}</span>
          </div>
          <span
            title={t("photosverified")}
            className={`${isMobile ? "" : "ml-auto"} model__icon`}
          >
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>{" "}
        </div>
        <span className="color-600 mt-12 h-48">{t("uplyourimg")}{'.'}</span>
        {!image && (
          <div className="verads__photo mt-12">
            <div className="verads__examplePhoto text-center">
              {t("example")}
              <br></br>
              {t("idphoto")}
              <img src={example1} alt={t("example")} />
            </div>
            <UploadImage
              setData={setImage}
              mainText={t("uploadphoto")}
              subText={t("photodisplay")}
              type={"is_verify"}
              setRemoveLink={setRemoveLink}
            />
          </div>
        )}

        {!!image && (
          <div className="verads__photo mt-12">
            <ExampleImage
              type={"superId"}
              image={image}
              setImage={setImage}
              removeLink={removeLink}
            />
            <PhotoUploadSuccess
              isCreatePage={isCreatePage}
              moderatedVerify={moderated}
              type={"photo"}
              at={verified_at}
              setIsVerificationExist={setIsVerificationExist}
              isVerificationExist={isVerificationExist}
              isBoosted={isBoosted}
              setIsBoosted={setIsBoosted}
            />
            {/* <WaitingAdmin /> */}
          </div>
        )}
        <div className={"verads__secure mt-8 mb-12"}>
          <Icon size={"4xl"} spritePath={"secure"} />
          {t("confyourimg")}
        </div>
        <div className="d-flex justify-sb align-center justify-center mt-16">
          <p className="color-main">{t("otherver")}:</p>
          {!isVideoRequest && <Button
            title={t("byvideocall")}
            clazz={"button--primary justify-center"}
            size={"m"}
            onClick={handleOpen}
          >
            {t("verification") + " " + t('byvideocall')}
            <span className="verads__ten">
              10{" "}
              <Icon title={t("EroCoins")} spritePath={"erocoin"} size={"s"} />
            </span>
          </Button>}
          {isVideoRequest && <Button
            title={t("byvideocall")}
            clazz={"button--green justify-center"}
            size={"m"}
            disabled={true}
          >
            <span>{t("moderated24hour") + ` ~ 24 ` + t("hourfew")}</span>
          </Button>}
        </div>
      </div>

      {open &&
        <FastVer
          setIsVideoRequest={setIsVideoRequest}
          whatsapp={whatsapp}
          telegram={telegram}
          open={open}
          setOpen={setOpen}
          profiles={profiles}
        />
      }
    </>
  );
};

export default VerAds;
