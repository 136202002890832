import {useState} from "react";
import {useTranslation} from "react-i18next";
import useLadyService from "@/services/LadyService";
const ShowMoreText = (props) => {
    const {
        children,
        clazz,
        min = 151,
        param = 'description'
    } = props
    const { t, lang } = useLadyService();
    const [readMore, setReadMore] = useState(false)
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    const getDescription = () => {
        if (Array.isArray(children) && children.length === 0) {
            return '';
        }
        
        if (typeof children === 'object' && !Array.isArray(children)) {
            if (children[lang]) {
                return readMore ? children[lang][param] : `${children[lang][param].slice(0, min)}... `;
            } else {
                return ''
            }
        } else {
            return readMore ? children : `${children.slice(0, min)}... `;
        }
    };

    return (
        !Array.isArray(children) && <p className={`p2${clazz ? ` ${clazz}` : ''} mt-0`}>

            {getDescription()}

            {getDescription().length <= min ?
              null :(
                <button className={'color-main'} onClick={() => setReadMore(!readMore)}>
                    {readMore ? "" : ` ${t('moreinfo')}`}
                </button>
              )}
        </p>
    );
}

export default ShowMoreText