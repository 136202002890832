import ToggleButton from "@/components/ui/toggleButton/ToggleButton";
import {Button, InputInLabel} from "@/components/ui";
import { useState, useEffect } from "react";
import { Popup } from "@/components/popups";
import useLadyService from "@/services/LadyService";
import Checkbox from "@/components/ui/checkbox/Checkbox";
import './publishPopup.scss'
import showToast from "@/components/toast/Toast";
import {makeRequest} from "@/services/makeRequest";



const PublishPopup = ({openPopup, setOpenPopup, partyId}) => {

    const { t, navigate } = useLadyService();

    const [sendToVip, setSendToVip] = useState(false)
    const [paySend, setPaySend] = useState(false)
    const [vipCount ,setVipCount] = useState(0)
    const [clientCount ,setClientCount] = useState(0)


    const toggleButtonOptions = [
            {
                title: t('toall'),
                value: false,
                id: "all_clients"
            },
            {title: t('onlyvip'), value: true, id: "vip_clients"},
    ]

    const getClients = async () => {
        try{
            const res = await makeRequest({
                route: `services/users-count`,
                method: "GET",
            });
            if(res){
                setClientCount(res.silver + res.gold + res.platinum)
                setVipCount(res.platinum + res.gold)
            }
        }
        catch(error){
            console.log(error)
            showToast({message: t("oops"), variant: "error"});
        }
        // setClientCount, setVipCount, partyId
    }

    const handlePublishParty = async (e) => {
        e.preventDefault()
        if(paySend){
            try{
                const res = await makeRequest({
                    route: `agency/parties/${partyId}/mailing`,
                    method: "POST",
                    payload: {
                        vip_only: sendToVip,
                    }
                });
                if (res)
                {
                    showToast({ message: t("success"), variant: "success" });
                }
            }
            catch(error){
                console.log('err ',error)
                showToast({message: t("oops"), variant: "error"});
            }
        }
        setOpenPopup(false);
        navigate(`lk/parties`)
    }

    useEffect(()=>{getClients()}, [])

    return(
        <Popup
            clazz={'p-24'}
            open={openPopup}
            id={"participatePopup"}
            setOpen={setOpenPopup}
        >
            <h2 className="mt-48">{t('publish')} {t('and')} {t('sendinvitations')}?</h2>
            

                <div className={`publish-checkBox-wrap mb-16 ${paySend ? 'active' : ''}`} >
                    <Checkbox 
                        clazz={'publish-checkBox'}
                        checked={paySend === false} 
                        onClick={()=>setPaySend(false)} 
                        id={'checkBox2'} 
                        title={t('nosendout')}
                    />
                </div >

                <div className={`publish-checkBox-wrap  ${!paySend ? 'active' : ''}`}>
                    <Checkbox 
                        clazz={'publish-checkBox'}
                        checked={paySend === true} 
                        onClick={()=>setPaySend(true)} 
                        id={'checkBox'} 
                        title={t('sendinvitations')}
                    />
                </div>
                
                
                {paySend &&
            <>
                <ToggleButton 
                    options={toggleButtonOptions} 
                    handleChange={setSendToVip} 
                    defaultValue={sendToVip}
                    clazz={'mt-16'}
                />
    
                
            </>}

            {paySend && 
            <p className="p1 text-center mt-16 ">
                    {sendToVip ? `${vipCount} ${t('vipinvites')}` : `${clientCount} ${t('vipinvites').replace('VIP-', '')}`}
            </p>}
            
            <div className="d-flex gap-12 mt-24">
                <Button 
                    size="l"
                    clazz="button button--green justify-center w-160 "
                    buttonType="submit"
                    onClick={(e)=>handlePublishParty(e)}
                >
                    {t('publish')}
                </Button>
                <Button
                    size="l"
                    clazz="button_outline--green-accent justify-center w-160"
                    onClick={()=>setOpenPopup(false)}>
                    {t('edit')}
                </Button>
            </div>

        </Popup>
    )
}
export default PublishPopup;