import { makeRequest } from "../../../services/makeRequest";
import useLadyService from "../../../services/LadyService";
import { useNavigate } from "react-router-dom";
import showToast from "../../toast/Toast";
import { Button } from "../../ui";
import { useState } from "react";

import Popup from "../Popup";

const PostAdPopup = ({
  elite,
  ver,
  helth,
  slug,
  name,
  setOpen,
  open,
  status,
}) => {
  const { lang, t } = useLadyService();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onPost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const route = `user/profile/${slug}/set-status`;
      const method = "PUT";
      const payload = {
        status: true,
      };

      await makeRequest({ route, method, payload });

      setIsLoading(false);

      navigate(`/lk/profiles`);

      showToast({
        message: `${t("ad")} ${name} ${t("publishedone")}`,
        variant: "success",
      });
    } catch (error) {
      setIsLoading(false);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  return (
    <Popup
      isLoading={isLoading}
      open={open}
      setOpen={setOpen}
      id={"postAd"}
      container={"div"}
    >
      <h2 className="mt-48">{`${t("suretopost")}:
        ${elite ? "Elite" : ""} ${helth ? t("healthy") : ""} ${
        ver ? t("verification") : ""
      }`}</h2>
      <div className="popup-form__inner">
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--secondary justify-center"}
            onClick={() => setOpen(false)}
          >
            {t("no")}
          </Button>

          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={onPost}
          >
            {t("yes")}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default PostAdPopup;
