import { object, ref, string } from "yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setRegistrationStatus,
  getRegistrationStatus,
  setAuthorizationStatus,
} from "@/stores/slices/popupSlice";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { setUserId, setUserType } from "@/stores/slices/userSlice";
import axios from "axios";
import { useEffect, useState } from "react";
import baseUrl from "@/services/apiConfig";
import { Checkbox, LoadingButton } from "../../ui";
import useLadyService from "@/services/LadyService";
import { Popup } from "../index";
import showToast from "../../toast/Toast";
import ConfirmPhone from "../../confirmPhone/ConfirmPhone";
import { makeRequest } from "../../../services/makeRequest";

const RegistrationPopup = (props) => {
  const { dataName } = props;

  const { dispatch, navigate, lang, t } = useLadyService();

  const [formErrors, setFormErrors] = useState({});
  const [phoneValue, setPhoneValue] = useState("");

  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
    phone: string().test(
      "phone-is-empty",
      t("phonenumerrequired"),
      function () {
        return !!phoneValue.replace("+", "");
      }
    ),
    password: string().min(8, t("min8")).required(),
    password_confirmation: string()
      .min(8, t("min8"))
      .required()
      .oneOf([ref("password")], t("passdonot")),
  });
  const [time, setTime] = useState(60);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [phoneInputComplete, setPhoneInputComplete] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [codeStatus, setCodeStatus] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [showCodeButton, setShowCodeButton] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);


  const fetchData = async () => {
    setLoadingCode(true);

    try {
      const route = `auth/send-code`;
      const method = "POST";
      const payload = {
        phone: phoneValue.replace(/[" "+]/g, ""),
      };

      const {result, message} = await makeRequest({ route, method, payload });
      if (!result) {
        if (message === "sms_send_failed") {
          showToast({
            message: t("phoneconfirmincorrect"),
            variant: "error",
          });
        }

      } else {
        setShowCodeInput(true);
        setShowCodeButton(false);

      }
      setLoadingCode(false);
    } catch (error) {
      console.log(error);
      setLoadingCode(false);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (phoneInputComplete) {
      // fetchData();
      setShowCodeButton(true);
    } else {
      if(canSubmit) {
        setCanSubmit(false)
      }
      if (showCodeButton) {
        setShowCodeButton(false);
      }
      if (showCodeInput) {
        setShowCodeInput(false);
      }
      if (codeStatus) {
        setCodeStatus(false);
      }

    }
  }, [phoneInputComplete]);

  const onClose = () => {
    reset();
    setPhoneValue("");
    dispatch(setRegistrationStatus(false));
    setFormErrors({});
  };
  const registrationState = useSelector(getRegistrationStatus);
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const sendCode = () => {
    fetchData();
  };
  const changeText = () => {
    switch (dataName) {
      case "indi":
        return t("indireg");

      case "agency":
        return t("agenci1");

      case "photograph":
        return t("photographer");

      default:
        return t("client");
    }
  };

  const uuiCode = localStorage.getItem("uui");

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (code.length !== 4) {
      setCodeError(true);
      return;
    }
    if (!phoneValue) {
      return;
    }
    setLoading(true);
    axios
      .post(`${baseUrl}auth/register`, {
        user_type: dataName,
        phone: phoneValue.replace(/[" "+]/g, ""),
        email: data.email,
        password: data.password,
        code: code,
        ...(uuiCode && { publish_code: uuiCode }),
      })
      .then((response) => {
        if (response.status === 201) {
          localStorage.removeItem("uui");
          const profileSlug = response.data.slug;
          axios
            .post(`${baseUrl}auth/login`, {
              identity: data.email,
              password: data.password,
            })
            .then((response) => {
              if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem("user", token);
                axios
                  .request({
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    method: "GET",
                    url: `${baseUrl}user/me`,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      const userType = response.data.data.user_type;
                      const userId = response.data.data.id;

                      dispatch(setUserId(userId));
                      if (userType) {
                        dispatch(setUserType(userType));

                        if (profileSlug) {
                          navigate(
                            `/${lang}/lk/profile/${profileSlug}/edit`
                          );
                        } else {
                          if (userType === "indi") {
                            navigate(`/${lang}/lk/profiles`);
                          } else if (userType === "customer") {
                            navigate(`/${lang}/lk/${userType}#tariffs`);
                          } else {
                            navigate(`/${lang}/lk/${userType}`);
                          }
                        }
                      }

                      try {
                        const currentToken = localStorage.getItem("user");

                        const arrFavorites = JSON.parse(
                          localStorage.getItem("favorites")
                        );

                        if (!!arrFavorites.length) {
                          axios
                            .request({
                              headers: {
                                Authorization: `Bearer ${currentToken}`,
                              },
                              method: "POST",
                              url: `${baseUrl}user/favorites/import`,
                              data: {
                                profiles: arrFavorites,
                              },
                            })
                            .then((response) => {
                              if (response.status === 200) {
                                localStorage.removeItem("favorites");
                              }
                            });
                        }
                      } catch (error) {}
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                onClose();
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        const errorStatus = error.response.status;
        const message = error.response.data.message;

        if (errorStatus === 400) {
          if (message === "verify_code_not_exist") {
            setFormErrors({
              login: {
                type: "is-email-or-phone",
                message: t("codeisincorrect"),
              },
            });
          } else {
            setFormErrors({
              login: {
                type: "is-email-or-phone",
                message: t("userexist"),
              },
            });
          }
        } else if (message === "code_not_valid") {
          setFormErrors({
            login: {
              type: "is-email-or-phone",
              message: t("codeisincorrect"),
            },
          });
        } else {
          showToast({
            message: t("oops"),
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigateToAuthorisation = () => {
    reset();
    dispatch(setAuthorizationStatus(true));
    dispatch(setRegistrationStatus(false));
  };

  const [checked, setChecked] = useState(false);
  const onChangeTherms = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Popup
      id={"registration"}
      open={!!registrationState}
      setOpen={onClose}
      onSubmit={handleSubmit(onSubmit)}
      closeOutside={false}
      clazz={"gap-12"}
    >
      <div className="popup-form__inner text-center gap-0">
        <h2 className="popup-form__title mt-48">{t("registration")}</h2>
        <p>{changeText()}</p>
      </div>
      <div className="popup-form__inner">
        <fieldset>
          <legend>
            {(errors.email && (
              <span className={"input-label__error"}>
                {errors.email.message}
              </span>
            )) || 
            <span>Email</span>}
          </legend>
          <InputInLabel
            clazz={`${errors.email ? " error" : ""}`}
            id={"loginRegistration"}
            type={"email"}
            placeholder={"example@google.com"}
            register={{ ...register("email") }}
            autocomplete={"email"}
            required={true}
            onChange={() => {
              if (errors.email) {
                clearErrors("email");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          >
          </InputInLabel>
        </fieldset>

        <fieldset>
          <legend>
            {(errors.phone && (
              <span className={"input-label__error"}>{errors.phone.message}</span>
            )) || <span>{t("phonenumber")}</span>}
          </legend>
          <InputInLabel
            clazz={`${errors.phone ? " error" : ""}`}
            id={"phoneRegistration"}
            title={t("pass")}
            type={"tel"}
            required={true}
            register={{ ...register("phone") }}
            autocomplete={"tel"}
            maskValue={phoneValue}
            setMaskValue={setPhoneValue}
            setError={setError}
            errorType={"phone"}
            setInputComplete={setPhoneInputComplete}
            onChange={() => {
              if (errors.phone) {
                clearErrors("phone");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          >
          </InputInLabel>
        </fieldset>
        {showCodeButton && (
          <LoadingButton
            isLoading={loadingCode}
            size={"l"}
            buttonType={"button"}
            clazz={"button--green"}
            onClick={() => sendCode()}
          >
            {t("getsmscode")}
          </LoadingButton>
        )}
        {showCodeInput && (
          <ConfirmPhone
          setCanSubmit={setCanSubmit}
            time={time}
            setTime={setTime}
            setShowCodeInput={setShowCodeInput}
            codeStatus={codeStatus}
            setFormCodeError={setCodeError}
            telephone={phoneValue.replace(/[" "+]/g, "")}
            isAuth={true}
            setCode={setCode}
            error={codeError}
            setCodeStatus={setCodeStatus}
          />
        )}
        {codeStatus === "success" && (
          <span className="color-green text-center p1">
            {t("phonesuccess")} <Icon size={"s"} spritePath={"check"} />

          </span>
        )}
        <fieldset>
          <legend>{t("pass")}</legend>
          <InputInLabel
            clazz={`${errors.password ? " error" : ""}`}
            id={"passRegistration"}
            type={"password"}
            required={true}
            placeholder={"••••••••"}
            register={{ ...register("password") }}
            autocomplete={"new-password"}
            onChange={() => {
              if (errors.password) {
                clearErrors("password");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          />
        </fieldset>

        <fieldset>
          <legend>{t("repeatpassword")}</legend>
          <InputInLabel
            clazz={`${errors.password_confirmation ? " error" : ""}`}
            id={"passConfirmRegistration"}
            required={true}
            type={"password"}
            placeholder={"••••••••"}
            register={{ ...register("password_confirmation") }}
            autocomplete={"new-password"}
            onChange={() => {
              if (errors.password_confirmation) {
                clearErrors("password_confirmation");
              }
              if (formErrors?.login?.message) {
                setFormErrors({});
              }
            }}
          />
        </fieldset>

        <Checkbox
          id={"agreeWithTherms"}
          clazz={"width-max align-self-center"}
          checked={checked}
          onChange={onChangeTherms}
          title={t("iagree")}
        />

        <p className={"m-0 p2 text-center"}>
          <Link target={"_blank"} className={"hover-line color-green"} to={`/${lang}/rules`}>
            {t("termsofserviceagree")}
          </Link>{" "}
          <br></br>
          {t("and")}
          <br></br>
          <Link target={"_blank"} className={"hover-line color-green"} to={`/${lang}/gdpr`}>
            {t("privacypolicy")}
          </Link>
        </p>

        <LoadingButton
          isLoading={loading}
          disabled={!checked || errors.password_confirmation || !canSubmit}

          size={"l"}
          buttonType={"submit"}
          clazz={"button--green"}
        >
          {t("register")}

        </LoadingButton>
        {(formErrors.login || errors.password_confirmation) && (
          <span className={"popup-form__error"}>
            {formErrors.login?.message || errors.password_confirmation.message}
          </span>
        )}
      </div>

      <hr />
      <div className="popup-form__box p-0 popup-form__bottom-link">
        <span className={"text-center fz-12 color-500"}>
          {t("haveaccount")}
        </span>
        <button type={"button"} onClick={() => navigateToAuthorisation()}>
          {t("login")}
        </button>
      </div>
    </Popup>
  );
};
export default RegistrationPopup;
