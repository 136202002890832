import { Button, ButtonLink, Icon } from "@/components/ui";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setMapMode,
  setStoriesMap,
  setVideoChatMode,
} from "@/stores/slices/popupSlice";

import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getMapStatus } from "@/stores/slices/popupSlice";
import React from "react";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import { setOrderModelStatus } from "@/stores/slices/popupSlice";
import { useSelector } from "react-redux";
import { setLadyForCall } from "../../../../../stores/slices/videoCallSlice";
import {makeRequest} from "../../../../../services/makeRequest";

const ModelButtonsGroup = ({ clazz = "", size, props }) => {
  const {
    oneProfileData,
    ready_videochat,
    phone,
    tag_booking,
    whatsapp,
    profileSlug,
    telegram,
  } = props;
  const mapPopupStatus = useSelector(getMapStatus);

  const videoRes = {
    profile: {
      name: oneProfileData?.name,
      slug: oneProfileData?.slug,
      photo: !!oneProfileData?.media?.photo?.length
        ? [oneProfileData?.media?.photo[0]]
        : null,
    },
  };

  const { dispatch, userType, windowWidth } = useLadyService();

  const { t } = useTranslation("translation");

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  return (
    <div className={`model__buttons ${clazz}`}>
      {!!tag_booking && !(userType === "indi" || userType === "agency") ? (
        <Button
          title={t("order")}
          onClick={() => {
            if (userType === "default") {
              dispatch(setAuthorizationStatus(true));
              dispatch(setAuthorisationAccessType("order"));
            } else {
              dispatch(setOrderModelStatus(true));
            }
          }}
          size={'l'}
          clazz={"button_outline--green"}
        >
          <Icon spritePath={"pay-content"} size={"l"} />
          {t("order")}
        </Button>
      ) : null}

      {!!ready_videochat && !(userType === "indi" || userType === "agency") ? (
        <Button
          onClick={() => {
            if (userType !== "default") {
              dispatch(setLadyForCall(videoRes));
            } else {
              dispatch(setVideoChatMode(true));
              dispatch(setAuthorizationStatus(true));
            }
            if (mapPopupStatus) {
              dispatch(setMapMode(true));
              dispatch(setStoriesMap(false));
            }
          }}
          clazz={`button--green ${windowWidth < 479.98 ? "button-icon" : ''}`}
          size={'l'}
          square={windowWidth < 479.98 ? true : null}
        >
          {windowWidth < 479.98 ? (
            <Icon size={"xl"} spritePath={"video"} />
          ) : (
            <>
              <Icon size={"l"} spritePath={"video"} />
              {t("videochat")}
            </>
          )}
        </Button>
      ) : null}

      <ButtonLink
        href={`tel:${phone}`}
        size={'l'}
        clazz={`button--primary flex-grow td-none${!tag_booking ? " w-100" : ""}`}
        square={null}
        onClick={()=>{
          makeRequest({route: `event/contact/profile/${oneProfileData?.slug}`, method: "POST"});
        }}
      >
          {phoneRes}
      </ButtonLink>

      {!!whatsapp ? (
        <ButtonLink
          href={`https://wa.me/${whatsapp}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${profileSlug}`}
          onClick={ () => {
            makeRequest({route: `event/contact/profile/${oneProfileData?.slug}`, method: "POST"});
          }}
          clazz={"button--primary button-icon"}
          square={true}
          size={'l'}
          target={"_blank"}
        >
          <Icon size={"xl"} spritePath={"whatsapp"} />
        </ButtonLink>
      ) : null}

      {!!telegram ? (
        <ButtonLink
          href={`https://t.me/${telegram}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${profileSlug}`}
          onClick={ () => {
            makeRequest({route: `event/contact/profile/${oneProfileData?.slug}`, method: "POST"});
          }}
          clazz={"button--primary button-icon"}
          square={true}
          size={'l'}
          target={"_blank"}
        >
          <Icon size={"xl"} spritePath={"telegram"} />
        </ButtonLink>
      ) : null}
    </div>
  );
};

export default ModelButtonsGroup;
