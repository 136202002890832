import { useSelector } from "react-redux";
import { Button, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { getFastVerStatus, setFastVerStatus } from "@/stores/slices/popupSlice";
import showToast from "@/components/toast/Toast";
import { makeRequest } from "@/services/makeRequest";
import { setBoosted } from "@/stores/slices/lkIndiSlice";
import {Popup} from "../index";

const FastVerification = ({
  setIsHealthBoosted = false,
  slug,
  type = "verification",
    callback = () => {}
}) => {
  const { dispatch, t, lang, userType } = useLadyService();
  const openStatus = useSelector(getFastVerStatus);
  const isTypeAgency = userType === "agency";
  const fastVerification = () => {
    const fetchData = async () => {
      const query =
        type === "verification"
          ? `profile/${slug}/verify-boost`
          : isTypeAgency ? `profile/${slug}/healthy-boost` : "healthy-boost";
      try {
        const route = `user/${query}`;
        const method = "POST";

        const {result} = await makeRequest({ route, method });

        if (result) {
          dispatch(setFastVerStatus(false));
          if (isTypeAgency) {
            if (type !== "verification" && setIsHealthBoosted) {
              setIsHealthBoosted(true);
            }
            if (type === "verification") {
              dispatch(setBoosted(slug));
            }
          } else {
            callback(true)
          }
        }
      } catch (error) {
        console.log(error);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    lang && fetchData();
  };

  return (
    <Popup
      open={!!openStatus}
      setOpen={() => dispatch(setFastVerStatus(false))}
      id={"fastVerification"}
      container={'div'}
    >

          <h2 className="text-center mt-48">{t("fastmoderated")}</h2>

        <div className="popup-form__inner mb-16 gap-8">
          <p className={"p2 text-center color-main"}>
            {t("fastmoderatedinfo")}
          </p>
          <span className="text-right">{"* " + t("worktimes")}</span>
        </div>
        <h3 className="color-main text-center currency--erocoin">
          {t("pricever") + " - 10"}
        </h3>
        <div className="d-flex justify-sb gap-16">
          <Button
            size={"l"}
            clazz={"button--secondary w-100"}
            onClick={() => {
              dispatch(setFastVerStatus(false));
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            size={"l"}
            clazz={"button--green w-100"}
            onClick={() => fastVerification()}
          >
            {" "}
            {t("fasttrack")}
          </Button>
        </div>
    </Popup>
  );
};
export default FastVerification;
