import { useEffect } from "react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "../index";
import PropTypes from "prop-types";

import {
  defaultRangeState,
  filterSelected,
  rangeState,
} from "@/stores/slices/filterSlice";

import { useTranslation } from "react-i18next";

import "./accordion.scss";

const Accordion = (props) => {
  const {
    title,
    clazz,
    clazzButton,
    children,
    onClick,
    type,
    name,
    open,
    noHead,
    onLoad
  } = props;

  const [isActive, setIsActive] = useState(open);
  const [height, setHeight] = useState('0px');
  const { t } = useTranslation('translation');
  const contentHeight = useRef(null);

  useEffect(() => {
    setIsActive(open);
  }, [open]);

  const updateHeight = () => {
    if (contentHeight.current) {
      setHeight(isActive ? `${contentHeight.current.scrollHeight}px` : '0px');
    }
  };

  useEffect(() => {
    setTimeout(()=>updateHeight(), 500);
  }, [isActive, children, onLoad]);
  
  
  function findArrayByKey(array, key) {
    for (const obj of array) {
      if (key in obj) {
        return obj[key];
      }
    }
    return null;
  }

  const filterSelectedSlice = useSelector(filterSelected);
  const rangeValue = useSelector(rangeState);
  const defaultRangeValue = useSelector(defaultRangeState);

  const [titleCheck, setTitleCheck] = useState(false);
  useEffect(() => {
    if (name) {
      const selectedAcc = findArrayByKey(filterSelectedSlice, name);
      setTitleCheck(selectedAcc && selectedAcc.length > 0 ? true : false);
    }
    if (
      name === 'breast' &&
      (rangeValue.weight.min > defaultRangeValue.weight.min ||
        rangeValue.weight.max < defaultRangeValue.weight.max ||
        rangeValue.height.min > defaultRangeValue.height.min ||
        rangeValue.height.max < defaultRangeValue.height.max)
    ) {
      setTitleCheck(true);
    }
    if(name === "search") {
      const selectedAcc = findArrayByKey(filterSelectedSlice, "radius");
      setTitleCheck(selectedAcc && selectedAcc.length > 0 ? true : false);
    }
  }, [filterSelectedSlice, rangeValue, name, defaultRangeValue]);

  return (
    <div
      name={name}
      className={`accordion${
      type ? ` accordion--${type}` : ""}${
      clazz ? ` ${clazz}` : ''}`}
    >
      {titleCheck && (
        <button onClick={onClick} className={`accordion__reset`} type="button">
          {t('reset')}
        </button>
      )}

      {!noHead && (
        <button
          className={`accordion-button${isActive ? ' _active' : ''} ${clazzButton ? ` ${clazzButton}` : ''}`}
          onClick={() => {
            setIsActive((prev) => !prev)
          }}
          type="button"
        >
          {title}
          {titleCheck && <span className={'filter__selected'}>!</span>}
          <Icon size={'m'} clazz={'chevrone'} spritePath={'chevrone-down'} />
        </button>
      )}

      <div
        ref={contentHeight}
        className={`accordion__body${isActive ? ' _show' : ''}`}
        style={{ height }}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  count: PropTypes.object,
  clazzButton: PropTypes.string,
};

export default Accordion;
