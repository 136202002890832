import {ButtonLink, Tippy, Icon} from "@/components/ui";
import {useEffect, useState} from "react";
import useLadyService from "@/services/LadyService";

import "./tariffsTable.scss";
import {Button, Loader} from "../ui";
import {ConfirmChangeTariffPopup} from "../popups";
import showToast from "../toast/Toast";
import {makeRequest} from "../../services/makeRequest";

const TariffsTable = ({refElem}) => {
  const {
    windowWidth,
    t,
    userTariff,
    userBalance,
    userTariffSilver,
    userTariffGold,
    userTariffPlatinum,
  } = useLadyService();

  const [loading, setLoading] = useState(true)

  const tariff = userTariff?.data?.length ? userTariff?.data[0] : "silver";
  const tabCount = tariff === "silver" ? 1 : tariff === "gold" ? 2 : 4;

  const [openPopup, setOpenPopup] = useState(false);

  const [tariffData, setTariffData] = useState([])

  const [selectedTariff, setSelectedTariff] = useState([])

  const checkBalance = (tariffType) => {
    const choosedTariff = (arr) => {
      setSelectedTariff(arr)
      setOpenPopup(true)
    }

    const showToastOnEmptyWallet = () => {
      showToast({
        type: "insuff",
        variant: 'booking'
      });
    }

    switch (tariffType) {
      case "Platinum":
        return userBalance >=  tariffData[2].price  ? choosedTariff(tariffData[2]) : showToastOnEmptyWallet()
        break

      case "Gold":
        return userBalance >= tariffData[1].price ? choosedTariff(tariffData[1]) : showToastOnEmptyWallet()
        break

      default:
        return choosedTariff(tariffData[0])
        break
    }
  }

  useEffect(() => {
    const getTariffsList = async () => {
      try {
        setLoading(true)

        const result = await makeRequest({
          route: `tariffs`,
          method: "GET"
        });

        if (result) {
          setTariffData(result.data)
        }
      } catch (error) {}
      finally {
        setLoading(false)
      }
    };
    getTariffsList()
  }, [])

  const [tab, setTab] = useState(String(tabCount));

  function changeTabOnClick(num) {
    if (windowWidth < 767.98) {
      setTab(num);
    }
  }

  function changeActiveTab(num) {
    if (windowWidth > 767.98) {
      setTab(num);
    }
  }

  return loading ? <Loader height={300} decimal={'px'}/> : (
    <>
      <div ref={refElem} data-active-tariff={tab} className="tariffs-table">
        <div className="tariffs-table__header">
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__heading"}>{t("options")}</div>
            <div className="tariffs-table__row tariffs-table__buttons">
              <button
                onClick={() => changeTabOnClick('1')}
                onMouseEnter={() => changeActiveTab('1')}
                type={"button"}
                className={`tariffs-table__silver${
                  !!userTariffSilver ? " _active" : ""
                }`}
              >
                Silver
              </button>
              <button
                onClick={() => changeTabOnClick('2')}
                onMouseEnter={() => changeActiveTab('2')}
                type={"button"}
                className={`tariffs-table__gold${
                  !!userTariffGold ? " _active" : ""
                }`}
              >
                Gold
              </button>
              <button
                onClick={() => changeTabOnClick('3')}
                onMouseEnter={() => changeActiveTab('3')}
                type={"button"}
                className={`tariffs-table__platinum${
                  !!userTariffPlatinum ? " _active" : ""
                }`}
              >
                Platinum
              </button>
            </div>
          </div>
        </div>

        <div className="tariffs-table__body">
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("guaranteeanon")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("favotitemodels")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("notificationsnew")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("retentionfilters")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("reviewsandcom")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>
          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("complaints")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("videochatswithmodel")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}><span className={'currency--erocoin'}>3</span>/{t("min")}
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}><span className={'currency--erocoin'}>2</span>/{t("min")}
              </div>
              <Tippy
                onMouseEnter={() => changeActiveTab('3')}
                title={`${t("include")} 30 ${t("minit")} ${t("next")} – 3 €`}
              >
                {t("free")}*
              </Tippy>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("erocontentaccess")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')}>{t("paid")}</div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                {t("discount")} 50%
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>{t("free")}</div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("wanotice")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>—</div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("orderescort")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("inviteseveral")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("verifytags")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>
              {t("invitestoparties")}
            </div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("vipprofile")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>

          <div className="tariffs-table__wrapper">
            <div className={"tariffs-table__title"}>{t("authenticity")}</div>
            <div className="tariffs-table__row">
              <div onMouseEnter={() => changeActiveTab('1')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('2')} className={"_gray"}>
                —
              </div>
              <div onMouseEnter={() => changeActiveTab('3')}>
                <Icon size={"s"} spritePath={"check"}/>
              </div>
            </div>
          </div>
        </div>

        <div className="tariffs-table__bottom">
          <div
            onMouseEnter={() => changeActiveTab('1')}
            className="tariffs-table__box"
          >
            <p>{t("free")}</p>

            <ButtonLink
              href={`/lk/customer`}
              size={"l"}
              clazz={"button--green w-100"}
              onClick={() => checkBalance('Silver')}
              disabled={userTariffSilver}
            >
              {t(userTariffSilver ? "currenttariff" : "select")}
            </ButtonLink>
          </div>

          <div
            onMouseEnter={() => changeActiveTab('2')}
            className="tariffs-table__box"
          >
            <p><span className={'currency--erocoin'}>{tariffData[1].price}</span>/{t("month")}</p>

            <Button
              size={"l"}
              clazz={"button--green w-100"}
              disabled={userTariffGold}
              onClick={() => checkBalance('Gold')}
            >
              {t(userTariffGold ? "currenttariff" : "select")}
            </Button>
          </div>

          <div
            onMouseEnter={() => changeActiveTab('3')}
            className="tariffs-table__box"
          >
            <p>
              <span className={'currency--erocoin'}>{tariffData[2].price}</span>
              {tariffData[2].old_price && <span className={'currency--erocoin'}>{tariffData[2].old_price}</span>}
            </p>

            <Button
              size={"l"}
              clazz={"button--green w-100"}
              disabled={userTariffPlatinum}
              onClick={() => checkBalance('Platinum')}
            >
              {t(userTariffPlatinum ? "currenttariff" : "select")}
            </Button>

            {tariffData[2].old_price && (
              <p className={"p2"}>
              <span className={"title fz-22 fz-20-mob mb-8"}>
                {t("soperaction")}
              </span>
                {t("promotion")}
              </p>
            )}
          </div>
        </div>
      </div>

      <ConfirmChangeTariffPopup
        open={openPopup}
        setOpen={setOpenPopup}
        data={selectedTariff}
      />
    </>
  );
};

export default TariffsTable;
