import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";

const ConfirmDeletePopup = (props) => {
  const { open, setOpen, onSubmit } = props;

  const { t } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      container={'div'}
      clazz={'gap-16'}
    >
        <h2 className="mt-48">{t('disforsure')}?</h2>
        
        <div className="d-column gap-16">
          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yes")}, {t("disconnect")}
          </Button>
        </div>
    </Popup>
  );
};

export default ConfirmDeletePopup;
