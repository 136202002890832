import {
  CountriesFilter,
  CheckPhone,
  Rules,
  InfoForm,
  InfoBlock,
  TogglePreview,
} from "../components";
import { useEffect, useState } from "react";
import useLadyService from "@/services/LadyService";
import NotifyBlock from "../components/notifyBlock/NotifyBlock";
import { PersonalData } from "../components/PersonalData.js";
import { AgencyDesc } from "./agencyDesc/AgencyDesc.js";
import { MediaZone } from "./mediaZone/MediaZone.js";
import TarifsGlobal from "../createProfile/pageComponent/stepSection/tarifs/TarifsGlobal.js";
import AccountActions from "../components/accountActions/AccountActions";
import useUserProfileService from "../../../../services/UserProfileService";

import "./AccountAgency.scss";
import ToggleSalon from "../components/toggleSalon/ToggleSalon";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgency,
  setAgency,
} from "../../../../stores/slices/userProfileSlice";
import axios from "axios";
import baseUrl from "../../../../services/apiConfig";
import showToast from "../../../toast/Toast";
import { Promos } from "./mediaZone/Promos";
import { useMakeRequest } from "../../../../hooks/useMakeRequest";

const AccountAgency = () => {
  const { windowWidth, t, userBalance, token } = useLadyService();
  const { profileCountStatus, blockedCountriesList } = useUserProfileService();

  const makeRequest = useMakeRequest();
  let data = {
    blocked_countries: blockedCountriesList,
  };

  const agency = useSelector(getAgency);
  
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(agency.logo);
  const [cover, setCover] = useState(agency.cover);
  const [interior, setInterior] = useState(agency.interior);
  const isSmallTablet = windowWidth < 1200.98;

  const goToAncketa = isSmallTablet ? t("toads") : t("browseprofiles");

  const goToBank = isSmallTablet ? t("tobalance") : t("gotobalance");

  const [videoPresentationValue, setVideoPresentationValue] = useState(
    agency.video_link
  );

  const [presentationValue, setPresentationValue] = useState(agency.video);
 
  const removeMedia = (link, callback = () => {}) => {
    axios
      .delete(link, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
      .then(() => {
        callback(link);
      })
      .catch((error) => {
        const res = error.response;
        switch (res?.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 404: {
            callback(link);
            break;
          }
          case 422: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
          default: {

          }
        }
      });
  };
  
  const uploadMedia = (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("type", type);
    return axios.post(baseUrl + `agency/media`, formData, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const updateAgencyInfo = async (data) => {
    await makeRequest({
      route: "user",
      method: "PUT",
      payload: { ...data },
    }).then((response) => {
      dispatch(setAgency(response.agency.data));
      showToast({
        message: t("success"),
        variant: "success",
      });
    });
  };

  useEffect(() => {
    if (logo !== undefined && logo.length && typeof logo[0] === "object") {
      uploadMedia(logo[0], "logo")
        .then((response) => {
          setLogo([response.data.link]);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  }, [logo, uploadMedia]);

  useEffect(() => {
    if (cover !== undefined && cover.length && typeof cover[0] === "object") {
      uploadMedia(cover[0], "cover")
        .then((response) => {
          setCover([response.data.link]);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  }, [cover, uploadMedia]);

  useEffect(() => {
    if (
      presentationValue !== undefined &&
      presentationValue.length &&
      typeof presentationValue[0] === "object"
    ) {
      uploadMedia(presentationValue[0], "video")
        .then((response) => {
          setPresentationValue([response.data.link]);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  }, [presentationValue, uploadMedia]);

  useEffect(() => {
    if (
      videoPresentationValue !== undefined &&
      videoPresentationValue &&
      agency.video_link !== videoPresentationValue &&
      videoPresentationValue.length
    ) {
      updateAgencyInfo({ video: videoPresentationValue })
        .then((r) => {})
        .catch();
    }
  }, [videoPresentationValue, uploadMedia]);

  useEffect(() => {
    interior.map(async (item, index) => {
      if (typeof item === "object") {
        await uploadMedia(item, "interior")
          .then((response) => {
            let currentInterior = [...interior];
            currentInterior[index] = response.data.link;
            setInterior(currentInterior);
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    });
  }, [interior, uploadMedia]);
  const [settings, setSettings] = useState(
    agency.settings?.base_settings?.discounts
      ? agency.settings.base_settings
      : { discounts: [], work_time: [] }
  );
  const updateSettings = (settings) => {
    makeRequest({
      route: "agency/save-base-settings",
      method: "PUT",
      payload: { base_settings: { ...settings } },
    }).then(() => {
      setSettings(settings);
      showToast({
        message: t("success"),
        variant: "success",
      });
    });
  };
  return (
    <div className={"account-page__root"}>
      <div className={"account-page__main"}>
        <PersonalData />

        <hr />

        {isSmallTablet && (
          <>
            <Rules />
            <hr />
          </>
        )}

        <AgencyDesc />

        <hr />

        <NotifyBlock />

        <InfoForm />

        <hr />

        <div className="account-page__toggles-wrapper">
          <ToggleSalon />

          <TogglePreview />
        </div>

        <hr />

        <div className={`d-flex justify-sb gap-16 mobile-fd-col agency-logo-banner`}>
          <MediaZone
            h3={t("agencylogo")}
            title={t("addagencylogo")}
            subtitle={t("prefersize") + " 360х240 px"}
            setMedia={setLogo}
            removeMedia={(media) => {
              removeMedia(media, () => {
                setLogo([]);
              });
            }}
            media={logo}
            isBigPhoto
          />

          <MediaZone
            media={presentationValue}
            presentationValue={presentationValue}
            setMedia={setPresentationValue}
            setPresentationValue={setPresentationValue}
            isBigPhoto={true}
            setVideoPresentationValue={setVideoPresentationValue}
            videoPresentationValue={videoPresentationValue}
            removeMedia={(media) => {
              removeMedia(media, () => {
                setPresentationValue([]);
              });
            }}
            h3={t("videopresentation")}
            title={
              t("uploadvideo") + " (" + t("maxval").toLowerCase() + " 20Mb)"
            }
            isVideo={true}
          />
        </div>

        <MediaZone
          h3={t("agenсycover")}
          title={t("addaagenсycover")}
          subtitle={t("prefersize") + " 1440х385 px"}
          setMedia={setCover}
          removeMedia={(media) => {
            removeMedia(media, () => {
              setCover([]);
            });
          }}
          media={cover}
          isBigPhoto
        />

        <div>
          <MediaZone
            h3={t("interiorphoto")}
            title={t("interdesc")}
            subtitle={t("showyourinter")}
            setMedia={setInterior}
            removeMedia={(media) => {
              removeMedia(media, () => {
                let newInterior = [...interior];
                newInterior = newInterior.filter((item) => item !== media);
                setInterior(newInterior);
              });
            }}
            media={interior}
            isBigPhoto
            interior={true}
            isMulti={true}
          />
        </div>

        <hr />

        <Promos removeMedia={removeMedia} />

        <hr />

        <InfoBlock
          text={profileCountStatus > 0 ? t("yourads") : t("addads")}
          count={profileCountStatus > 0 ? profileCountStatus : ""}
          link={"/lk/profiles"}
          linkText={goToAncketa}
        />

        <InfoBlock
          text={t("yourbalance")}
          count={userBalance}
          balance
          link={"/lk/balance"}
          linkText={goToBank}
        />

        <div>
          <h2>{t("defaultset")}</h2>

          <p>{t("customize")}</p>

          <div className={"account-page__info"}>
            <TarifsGlobal
              setFormData={updateSettings}
              formData={settings}
              isAgency={true}
            />
          </div>

          <hr style={{ margin: "24px 0px" }} />

          <div className="account-page__info">
            <h2>{t("usefulfeatures")}</h2>
            <CheckPhone />

            <CountriesFilter data={data} />
          </div>
        </div>

        <hr />

        <AccountActions />
      </div>

      {!isSmallTablet && <Rules />}
    </div>
  );
};

export default AccountAgency;
