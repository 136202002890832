import { isFilterSearch } from "@/stores/slices/filterSlice";
import { Button, Icon } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";

import "./girlMap.scss";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";

const GirlMap = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const isFilterSelected = useSelector(isFilterSearch);
  const currentCity = useSelector(getUserCurrentCity);
  const postTitle = t("h3main", {City: currentCity.title ? currentCity.title : ""})

  const postFindnear = t("findnear")?.replace(/%([^%]+)%/g, currentCity.title);
  return (
    <section className={"girl-map__container"} id="models-map">
      <div className="girl-map__inner">
        <div className={"girl-map__content"}>
          <h2 className="mb-32">
            {!isFilterSelected ? postTitle : t("matching")}
          </h2>
          <Button
            size={"l"}
            clazz={`button--green-tp`}
            onClick={() => {dispatch(setStoriesMap(true));}}
          >
            {t("onmap")}
            <Icon size={"l"} spritePath={"map"} />
          </Button>
          <p2 className='mt-32 mb-0'>{postFindnear}</p2>
        </div>
      </div>
    </section>
  );
};

export default GirlMap;
