import { useCallback, useRef, useState } from "react";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useTitle from "@/hooks/useTitle";
import useLadyService from "@/services/LadyService";
import { Icon, InputInLabel, Button, Checkbox } from "@/components/ui";
import { Crypto } from "./typeBalance/Crypto";
import { Link } from "react-router-dom";
import { Bank } from "./typeBalance/Bank";
import { makeRequest } from "@/services/makeRequest";

import showToast from "../../toast/Toast";
import { LoadingButton } from "../../ui";
import { Paypal } from "./typeBalance/Paypal";
import { useSelector } from "react-redux";
import { getUserBalance, getUserHold } from "@/stores/slices/userSlice";

const Balance = () => {
  const { t, userId, resultBalance } = useLadyService();
  const windowWidth = window.innerWidth;
  const isTablet = windowWidth < 1000;
  const isMobile = windowWidth < 767.98;

  const [isRulesCheck, setIsRulesCheck] = useState(false);
  const [tubValue, setTubValue] = useState("bank");

  const currentBalance = useSelector(getUserBalance);
  const hold = useSelector(getUserHold);

  const defaultValue = ((currentBalance - hold) / 2).toFixed();

  const validShape = (type) => {
    return string().when([], {
      is: () => type,
      then: (schema) => schema.required(t("required")),
      otherwise: (schema) => schema.notRequired(),
    });
  };
  const [active, setActive] = useState({
    paypal: true,
    crypto: false,
    bank: false,
  });
  const paypalShape = validShape(active.paypal);

  const cryptoShape = validShape(active.crypto);

  const bankShape = validShape(active.bank);

  const bankNumberShape = string().when([], {
    is: () => tubValue === "bank" && active.bank,
    then: (schema) => schema.required(t("required")),
    otherwise: (schema) => schema.notRequired(),
  });

  const bankIBANShape = string().when([], {
    is: () => tubValue === "iban" && active.bank,
    then: (schema) => schema.required(t("required")),
    otherwise: (schema) => schema.notRequired(),
  });

  const formSchema = object().shape({
    paypal: paypalShape,
    name: paypalShape,
    lastName: paypalShape,

    wallet: cryptoShape,

    bank_number: bankNumberShape,
    code: bankNumberShape,

    iban: bankIBANShape,
    swift: bankIBANShape,

    bank_name: bankShape,
    bank_lastName: bankShape,
  });
  const {
    register,
    formState: { errors },
    getValues,
    clearErrors,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const values = getValues();

  const setValueFrom = (name, value) => {
    setValue(name, value);
  };
  const setValueTo = (name, value) => {
    setValue(name, value);
  };

  const BalanceTypeInfo = () => {
    return (
      <>
        {active.paypal && (
          <Paypal
            defaultValue={defaultValue}
            currency={currency}
            setCurrency={setCurrency}
            values={values}
            setValueTo={setValueTo}
            setValueFrom={setValueFrom}
            registerTo={{ ...register("price-paypal-coin") }}
            registerFrom={{ ...register("price-paypal-euro") }}
          ></Paypal>
        )}
        {active.crypto && (
          <Crypto
            defaultValue={defaultValue}
            currency={currencyCrypto}
            setCurrency={setCurrencyCrypto}
            values={values}
            setValueTo={setValueTo}
            setValueFrom={setValueFrom}
            registerTo={{ ...register("price-crypto-coin") }}
            registerFrom={{ ...register("price-crypto-euro") }}
          ></Crypto>
        )}
        {active.bank && (
          <Bank
            defaultValue={defaultValue}
            tubValue={tubValue}
            setTubValue={setTubValue}
            userId={userId}
            currency={currency}
            setCurrency={setCurrency}
            values={values}
            setValueTo={setValueTo}
            setValueFrom={setValueFrom}
            registerTo={{ ...register("price-bank-coin") }}
            registerFrom={{ ...register("price-bank-euro") }}
          ></Bank>
        )}
      </>
    );
  };

  const [invoiceLoad, setInvoiceLoad] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState("eur");
  const [currencyCrypto, setCurrencyCrypto] = useState("usdt");

  const [rulesError, setRulesError] = useState(false);
  const rulesRef = useRef(null);

  const handleClick = () => {
    setRulesError(true);
    rulesRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useTitle(t("balance"));

  const Form = () => {

    // const {
    //   register,
    //   formState: { errors },
    //   getValues,
    //   clearErrors,
    //   handleSubmit,
    //   setValue,
    // } = useForm({
    //   mode: "onSubmit",
    //   resolver: yupResolver(formSchema),
    // });
    const onSubmit = (data) => {
      const fetchData = async () => {
        setIsLoading(true);
        let amount = resultBalance / 2;
        let currencyResult = "";
        if (!active.crypto) {
          currencyResult = currency === "eur" ? "eur" : "czk";
        } else {
          currencyResult = currencyCrypto === "usdt" ? "usdt" : "btc";
        }

        try {
          const route = `payment/withdraw`;

          const method = "POST";
          const payload = {
            amount: data[`price-${data.account}-coin`] || amount,
            method:
              data.account === "paypal"
                ? "paypal"
                : data.account === "crypto"
                ? "confirmo"
                : "invoice",
            currency: currencyResult,
            data: {
              ...(active.paypal && { name: data.name }),
              ...(active.paypal && { last_name: data.lastName }),
              ...(active.paypal && { paypal_acc: data.paypal }),
              ...(active.crypto && { wallet: data.wallet }),
              ...(active.bank &&
                tubValue === "bank" && { account_number: data.bank_number }),
              ...(active.bank && tubValue === "bank" && { code: data.code }),
              ...(active.bank && tubValue === "iban" && { iban: data.iban }),
              ...(active.bank && tubValue === "iban" && { swift: data.swift }),
              ...(active.bank && { name: data.bank_name }),
              ...(active.bank && { last_name: data.bank_lastName }),
            },
          };

          const result = await makeRequest({ route, method, payload });
          if(result) {
            showToast({
              message: t('drawsuccess'),
              variant: "success",
            });
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          showToast({
            message: t("oops"),
            variant: "error",
          });
        }
      };
      fetchData();
    };
    return (
      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onSubmit)}
        className="flex-grow"
      >
        <div className="verads control" ref={rulesRef}>
          <BalanceTypeInfo />
          {active.paypal && (
            <div
              className={`${
                isMobile ? "d-flex gap-8 fd-column" : "short-filter__group"
              } mt-24 mb-16`}
            >
              <fieldset>
                <legend>{errors.paypal ?
                    <span className={"error create-error text-left"}>
                      {errors.paypal.message}
                    </span>
                  :
                  <span>{"Paypal " + t("account") + " (Email)"}</span>}
                  </legend>
                <InputInLabel
                  clazz={`required ${
                    errors.paypal ? ` error ${isMobile ? "mb-16" : "mb-12"}` : ""
                  } ${isMobile ? "grid-col-1" : ""} w-100`}
                  id={"balance-paypal"}
                  type={"text"}
                  placeholder={""}
                  register={{ ...register("paypal") }}
                  autocomplete={"paypal"}
                  onChange={() => {
                    if (errors.paypal) {
                      clearErrors("paypal");
                    }
                  }}
                >
                  
                </InputInLabel>
              </fieldset>
              <fieldset>
                <legend>{errors.name ?
                    <span className={"error create-error text-left"}>
                      {errors.name.message}
                    </span>
                  :
                  <span>{t("names")}</span>}</legend>
                <InputInLabel
                  clazz={`required ${
                    errors.name ? ` error ${isMobile ? "mb-16" : "mb-12"}` : ""
                  } ${isMobile ? "grid-col-1" : ""} w-100`}
                  id={"balance-pay--name"}
                  type={"text"}
                  placeholder={t("inputname")}
                  register={{ ...register("name") }}
                  autocomplete={"name"}
                  onChange={() => {
                    if (errors.name) {
                      clearErrors("name");
                    }
                  }}
                >
                  
                </InputInLabel>
              </fieldset>
              <fieldset>
                <legend>{errors.lastName ? 
                    <span className={"error create-error text-left"}>
                      {errors.lastName.message}
                    </span>
                  :
                  <span>{t("family")}</span>}</legend>
                <InputInLabel
                  clazz={`required ${
                    errors.lastName
                      ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                      : ""
                  } ${isMobile ? "grid-col-1" : ""} w-100`}
                  id={"balance-pay--lastName"}
                  type={"text"}
                  placeholder={t("inputsurname")}
                  register={{ ...register("lastName") }}
                  autocomplete={"lastName"}
                  onChange={() => {
                    if (errors.lastName) {
                      clearErrors("lastName");
                    }
                  }}
                >
                  
                </InputInLabel>
              </fieldset>
            </div>
          )}
          {active.crypto && (
            <div
              className={`d-flex gap-16 mt-24 mb-16 ${
                isMobile ? "fd-column" : ""
              }`}
            >
              <fieldset>
                <legend>{errors.wallet ?
                    <span className={"error create-error text-left"}>
                      {errors.wallet.message}
                    </span>
                  :
                  <span>{t("waletnumber")}</span>}</legend>
                <InputInLabel
                  clazz={`required ${
                    errors.wallet ? ` error ${isMobile ? "mb-16" : "mb-12"}` : ""
                  } ${isMobile ? "grid-col-1" : ""} w-100`}
                  id={"balance-pay--wallet"}
                  type={"text"}
                  placeholder={""}
                  register={{ ...register("wallet") }}
                  autocomplete={"wallet"}
                  onChange={() => {
                    if (errors.wallet) {
                      clearErrors("wallet");
                    }
                  }}
                >
                </InputInLabel>
              </fieldset>
              <p className={isMobile ? `color-main` : `w-50 color-main`}>{t("amounttransfer")}</p>
            </div>
          )}
          {active.bank && (
            <div className="model__params gap-16 mt-16">
              {tubValue === "bank" && (
                <>
                  <fieldset>
                    <legend>{errors.bank_number ?
                        <span className={"error create-error text-left"}>
                          {errors.bank_number.message}
                        </span>
                      :
                      <span>{t("accnumber")}</span>}</legend>
                    <InputInLabel
                      clazz={`required ${
                        errors.bank_number
                          ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                          : ""
                      } ${isMobile ? "grid-col-1" : ""} w-100`}
                      id={"balance-bank--number"}
                      type={"text"}
                      placeholder={""}
                      register={{ ...register("bank_number") }}
                      autocomplete={"bank_number"}
                      onChange={() => {
                        if (errors.bank_number) {
                          clearErrors("bank_number");
                        }
                      }}
                    >
                      
                    </InputInLabel>
                  </fieldset>
                  <fieldset>
                    <legend>{errors.code ?
                        <span className={"error create-error text-left"}>
                          {errors.code.message}
                        </span>
                      :
                      <span>{t("bankcode")}</span>}</legend>
                    <InputInLabel
                      clazz={`required ${
                        errors.code
                          ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                          : ""
                      } ${isMobile ? "grid-col-1" : ""} w-100`}
                      id={"balance-bank--code"}
                      type={"text"}
                      placeholder={""}
                      register={{ ...register("code") }}
                      autocomplete={"code"}
                      onChange={() => {
                        if (errors.code) {
                          clearErrors("code");
                        }
                      }}
                    >
                      
                    </InputInLabel>
                  </fieldset>
                </>
              )}
              {tubValue === "iban" && (
                <>
                  <fieldset>
                    <legend>{errors.iban ?
                        <span className={"error create-error text-left"}>
                          {errors.iban.message}
                        </span>
                      :
                      <span>{"IBAN"}</span>}</legend>
                    <InputInLabel
                      clazz={`required ${
                        errors.iban
                          ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                          : ""
                      } ${isMobile ? "grid-col-1" : ""} w-100`}
                      id={"balance-bank--number"}
                      type={"text"}
                      placeholder={""}
                      register={{ ...register("iban") }}
                      autocomplete={"iban"}
                      onChange={() => {
                        if (errors.iban) {
                          clearErrors("iban");
                        }
                      }}
                    >
                      
                    </InputInLabel>
                  </fieldset>
                  <fieldset>
                    <legend>{errors.swift ?
                        <span className={"error create-error text-left"}>
                          {errors.swift.message}
                        </span>
                      :
                      <span>{"BIC (SWIFT)"}</span>}</legend>
                    <InputInLabel
                      clazz={`required ${
                        errors.swift
                          ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                          : ""
                      } ${isMobile ? "grid-col-1" : ""} w-100`}
                      id={"balance-bank--swift"}
                      type={"text"}
                      placeholder={""}
                      register={{ ...register("swift") }}
                      autocomplete={"swift"}
                      onChange={() => {
                        if (errors.swift) {
                          clearErrors("swift");
                        }
                      }}
                    >
                      
                    </InputInLabel>
                  </fieldset>
                </>
              )}
              <fieldset>
                <legend>{errors.bank_name ?
                    <span className={"error create-error text-left"}>
                      {errors.bank_name.message}
                    </span>
                  :
                  <span>{t("names")}</span>}</legend>
                <InputInLabel
                  clazz={`required ${
                    errors.bank_name
                      ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                      : ""
                  } ${isMobile ? "grid-col-1" : ""} w-100`}
                  id={"balance-bank--name"}
                  type={"text"}
                  placeholder={t("inputname")}
                  register={{ ...register("bank_name") }}
                  autocomplete={"name"}
                  onChange={() => {
                    if (errors.bank_name) {
                      clearErrors("bank_name");
                    }
                  }}
                >
                  
                </InputInLabel>
              </fieldset>
              <fieldset>
                <legend>{errors.bank_lastName ?
                    <span className={"error create-error text-left"}>
                      {errors.bank_lastName.message}
                    </span>
                  :
                  <span>{t("family")}</span>}</legend>
                <InputInLabel
                  clazz={`required ${
                    errors.bank_lastName
                      ? ` error ${isMobile ? "mb-16" : "mb-12"}`
                      : ""
                  } ${isMobile ? "grid-col-1" : ""} w-100`}
                  id={"balance-bank--lastName"}
                  type={"text"}
                  placeholder={t("inputsurname")}
                  register={{ ...register("bank_lastName") }}
                  autocomplete={"bank_lastName"}
                  onChange={() => {
                    if (errors.bank_lastName) {
                      clearErrors("bank_lastName");
                    }
                  }}
                >
                </InputInLabel>
              </fieldset>
            </div>
          )}
          <>
            <Checkbox
              onChange={() => {
                setIsRulesCheck((prev) => !prev);
                if (rulesError) {
                  setRulesError(false);
                }
              }}
              checked={isRulesCheck}
              id={"pay-check-rules"}
              clazz={`width-max mt-8 ${rulesError ? "" : "mb-16"}`}
              title={t("iagree")}
            >
              <Link
                to={"/rules"}
                target="_blank"
                className={"color-green hover-line"}
              >
                {t("portalules")}
              </Link>
            </Checkbox>
            {rulesError && (
              <span className="color-red-700">{t("required")}</span>
            )}
          </>
          <div
            className={`d-flex gap-16 mt-auto ${
              isMobile && windowWidth !== 0 ? "mt-8 fd-column" : ""
            }`}
          >
            <fieldset className={isMobile ? "w-100" : "w-50"}>
              <legend>{errors.email ?
                  <span className={"error create-error text-left"}>
                    {errors.email.message}
                  </span>
                :
                <span>{t("wherereceipt")}</span>}</legend>
              <InputInLabel
                clazz={`${
                  errors.email ? ` error ${isMobile ? "mb-16" : "mb-12"}` : ""
                } w-100`}
                id={"balance-pay"}
                type={"email"}
                placeholder={"Email"}
                register={{ ...register("email") }}
                autocomplete={"email"}
                onChange={() => {
                  if (errors.email) {
                    clearErrors("email");
                  }
                }}
              >
                
              </InputInLabel>
            </fieldset>
            {invoiceLoad && (
              <LoadingButton
                buttonType={"button"}
                isLoading={invoiceLoad}
                size={"l"}
                clazz={"button--primary"}
              >
                {t("continue")}
              </LoadingButton>
            )}
            {!isLoading && (
              <Button
                disabled={!isRulesCheck}
                requiredThrow={!isRulesCheck}
                fooThrow={() => handleClick()}
                size={"l"}
                buttonType={"submit"}
                wrapperClazz={`${
                  isMobile ? "grid-col-1 w-100" : ""
                }`}
                clazz={`button--green ${
                  isMobile ? "grid-col-1 w-100" : ""
                }`}
              >
                {t("send")}
              </Button>
            )}
            {isLoading && (
              <LoadingButton
                buttonType={"submit"}
                isLoading={isLoading}
                size={"l"}
                clazz={"button--green"}
              >
                {t("continue")}
              </LoadingButton>
            )}
          </div>
        </div>
      </form>
    );
  };

  return (
    <main>
      <section className="registration__container">
        <h1 className="text-center">{t("withdraw")}</h1>
        <h2>{t("selectmeth") + " " + t("widthrow")}</h2>
        <div className="registration__account">
          <div className="registration__radio-group topup withdraw">
            <label
              htmlFor="pay-paypal"
              className={`registration__radio${
                active.paypal ? " _active" : ""
              }`}
              onClick={() =>
                setActive({
                  card: false,
                  paypal: true,
                  crypto: false,
                  bank: false,
                })
              }
            >
              {!isTablet && windowWidth !== 0 && (
                <>
                  <span className="d-flex align-center">
                    <Icon
                      size={"m"}
                      spritePath={"card"}
                      clazz={`mr-4 ${active.paypal ? "color-green" : ""}`}
                    />
                    {`PayPal ${t("or") + " " + t("linkdecard")}`}
                  </span>
                  {t("earingin") + " 1-2 " + t("workdays")}
                </>
              )}
              {isTablet && windowWidth !== 0 && <span>{"PayPal"}</span>}
              <input
                {...register("account")}
                id={"pay-paypal"}
                type="radio"
                value={"paypal"}
                defaultChecked={active.paypal}
              />
              <p className={"p1 color-green"}>{t("fee") + " PayPal"} - 1%</p>
            </label>
            <label
              htmlFor="pay-crypto"
              className={`registration__radio${
                active.crypto ? " _active" : ""
              }`}
              onClick={() =>
                setActive({
                  card: false,
                  paypal: false,
                  crypto: true,
                  bank: false,
                })
              }
            >
              {!isTablet && windowWidth !== 0 && (
                <>
                  <span className="d-flex align-center">
                    <Icon
                      size={"m"}
                      spritePath={"bitcoin"}
                      clazz={`mr-4 ${active.crypto ? "color-green" : ""}`}
                    />
                    {t("i-crypto")}
                  </span>
                  {t("earingin") + " 1-2 " + t("workdays")}
                </>
              )}
              {isTablet && windowWidth !== 0 && <span>{t("crypto")}</span>}
              <input
                {...register("account")}
                id={"pay-crypto"}
                type="radio"
                value={"crypto"}
                defaultChecked={active.crypto}
              />
              <p className={"p1 color-green"}>{t("fee") + " Confirmo"} - 1%</p>
            </label>
            <label
              htmlFor="pay-bank"
              className={`registration__radio${active.bank ? " _active" : ""}`}
              onClick={() =>
                setActive({
                  card: false,
                  crypto: false,
                  paypal: false,
                  bank: true,
                })
              }
            >
              {!isTablet && windowWidth !== 0 && (
                <>
                  <span className="d-flex align-center">
                    <Icon
                      size={"m"}
                      spritePath={"bank"}
                      clazz={`mr-4 ${active.bank ? "color-green" : ""}`}
                    />
                    {t("toacc") + " " + t("inbank") + " (IBAN)"}
                  </span>
                  {t("earingin") + " 1-2 " + t("workdays")}
                </>
              )}
              {isTablet && windowWidth !== 0 && <span>{t("toacc")}</span>}
              <input
                {...register("account")}
                id={"pay-bank"}
                type="radio"
                value={"bank"}
                defaultChecked={active.bank}
              />
              <p className={"p1 color-green"}>{t("freeoffee")}</p>
            </label>
          </div>
          <Form />
        </div>
      </section>
    </main>
  );
};
export default Balance;
