import {
  setForgotPasswordStatus,
  setForgotPasswordPhoneStatus,
  setForgotPasswordPhoneInputsStatus,
  getForgotPasswordPhoneStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { string, object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import RestorePhoneInputsPopup from "./RestorePhoneInputsPopup";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import { Popup } from "../index";
import { LoadingButton } from "../../ui";
import showToast from "../../toast/Toast";
import {t} from "i18next";
import { setForgotPasswordEmailStatus } from "../../../stores/slices/popupSlice";

const RestorePasswordPopup = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const forgotPassPhoneState = useSelector(getForgotPasswordPhoneStatus);
  const formSchema = object().shape({
    phone: string().test(
      "phone-is-empty",
      t("phonenumerrequired"),
      function () {
        return !!phoneValue.replace("+", "");
      }
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [phoneValue, setPhoneValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneComplete, setIsPhoneComplete] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const route = `auth/password/reset`;
        const method = "POST";
        const payload = {
          identity: phoneValue.replace(/[" "+]/g, ""),
        };

        const {result, message} = await makeRequest({ route, method, payload });
        if (!result) {
          showToast({
            message: t(message),
            variant: "error",
          });
        } else {
          showToast({
            message: t('codesended'),
            variant: "success",
          });
          dispatch(setForgotPasswordPhoneStatus(false));
          dispatch(setForgotPasswordPhoneInputsStatus(true));
        }
        reset()
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };
    fetchData();
    // let result = getValues("phone");
    // setTelephone(result);
    //dispatch(setForgotPasswordPhoneStatus(false))
  };

  const onClose = () => {
    reset();
    setIsLoading(false)
    dispatch(setForgotPasswordPhoneStatus(false));
  };

  return (
    <>
      {!!forgotPassPhoneState && (
        <Popup
          id={"forgotPasswordPhonePopup"}
          open={!!forgotPassPhoneState}
          setOpen={onClose}
          method={"POST"}
          onSubmit={handleSubmit(onSubmit)}
          wrapperClazz="forgot-email-popup"
        >
          <h2 className={"popup-form__text mt-48"}>{t("yourphone")}</h2>
          <div className="popup-form__inner">
            <fieldset>
              <legend>{errors.phone ? t('phoneuncorrenct') : t("phonenumber")}</legend>
              <InputInLabel
                clazz={`${errors.phone ? " error" : ""}`}
                id={"forgotPassPhone"}
                type={"tel"}
                maskValue={phoneValue}
                setMaskValue={setPhoneValue}
                register={{ ...register("phone") }}
                autocomplete={"tel"}
                setError={setError}
                errorType={"phone"}
                clearErrors={clearErrors}
                setInputComplete={setIsPhoneComplete}
                onChange={() => {
                  if(errors.phone) {
                    clearErrors('phone')
                  }
                }}
              >
               
              </InputInLabel>
            </fieldset>

            <LoadingButton
              disabled={!isPhoneComplete}
              buttonType={"submit"}
              size={"l"}
              square={false}
              isLoading={isLoading}
              clazz="button--green min-w-160"
            >
              {t("getsmscode")}
            </LoadingButton>
          </div>
        </Popup>
      )}

      <RestorePhoneInputsPopup telephone={phoneValue} />
    </>
  );
};

export default RestorePasswordPopup;
