import "./connectPromotionAd.scss";

import { Button, Checkbox, Icon } from "@/components/ui";
import { useEffect, useRef, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";

import MinBalancePopup from "../minBalance/MinBalancePopup";
import useLadyService from "../../../services/LadyService";
import { Link } from "react-router-dom";
import { InputInLabel } from "../../ui";
import InputWithArrows from "../../ui/input/inputWithArrows/InputWithArrows";
import {Popup} from "../index";

const ConnectPromotionAdPopup = (props) => {
  const {
    open,
    setOpen,
    position,
    price,
    currentPrice,
    slugProfile,
    setPrice,
    setPosition,
    setPromotion,
    setOpenAll,
    name,
    city_id,
    dynamicTop,
    setProfilesData,
  } = props;

  // console.log('ConnectPromotionAdPopup',props)

  const { t } = useLadyService();

  const [zeroBalance, setZeroBalance] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isLow, setIsLow] = useState(false);

  const [full, setFull] = useState(true);
  const [date, setDate] = useState(null);

  const mainPrice = useRef(Number(price));
  const [auctionPrice, setAuctionPrice] = useState(mainPrice.current);

  const handleClickUp = () => {
    setAuctionPrice((prev) => prev + 1);
  };

  const handleClickDown = () => {
    if (!!auctionPrice) {
      setAuctionPrice((prev) => prev - 1);
    } else if(auctionPrice <= 1){
      setAuctionPrice(1);
    }
  };

  const onChangeFull = () => {
    setFull((prev) => !prev);
  };

  const onChangeAuction = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");

    setAuctionPrice(Number(numericValue));
  };

  const onChangeDate = (value) => {
    setDate(value);
    if(full)setFull(false)
  };

  const validNew = t("valid")[0].toUpperCase() + t("valid").slice(1);

  const dt_obj = new Date(date);
  const ts_unix = dt_obj.getTime() / 1000;

  const dateRes = full ? null : !!date ? ts_unix : null;

  const text = t("promoteadto");

  const words = text.split(" ");

  const firstTwoWords = words.slice(0, 2).join(" ");

  const restOfText = words.slice(2).join(" ");

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `places/up/${slugProfile}`;

        const method = "POST";
        const payload = {
          price: auctionPrice,
          ...(dateRes && { expired_at: dateRes }),
        };

        const res = await makeRequest({ route, method, payload });

        if (res.message === "balance_insufficient") {
          setIsLoading(false);
          setZeroBalance(true);
        } else if (dynamicTop) {
          const route = `user/profiles`;
          const method = "GET";

          const payload = {
            city: city_id,
            limit: 1000,
          };

          const { profiles } = await makeRequest({ route, method, payload });

          setProfilesData((prevData) => {
            const updatedData = [...prevData];

            profiles.forEach((profile) => {
              const index = updatedData.findIndex(
                (item) => item.slug === profile.slug
              );

              if (index !== -1) {
                updatedData[index] = profile;
              }
            });

            return updatedData;
          });

          setIsLoading(false);
          showToast({
            message: t("activepromo"),
            variant: "success",
          });

          setOpen(false);
        } else if (!dynamicTop) {
          setPromotion(dateRes);
          setPosition(position);
          setPrice(auctionPrice);
          setIsLoading(false);
          showToast({
            message: t("activepromo"),
            variant: "success",
          });

          setOpen(false);
        }
      } catch (error) {}
    };

    if (mainPrice.current > auctionPrice) {
      setIsLow(true);
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    setIsLow(mainPrice.current > auctionPrice);
  }, [auctionPrice]);

  return (
    <>
      <Popup
        id={'connectPromotionAdPopup'}
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        container={'div'}
        clazz={'gap-16'}
        
      >
        <h2 className="mt-48">
          {firstTwoWords}{" "}
          <Link target="_blank" to={`/profile/${slugProfile}`}>{name}</Link>
          <br /> {restOfText} <br />
          <span className="color-green title_h2">{position}{t("th")} {t("place")}</span>
        </h2>
        
        <span className={"color-main text-center p2"}>{t("adplace")}</span>

        <span className="ver__block__money m-auto event-none">
          {t("minauc")} &mdash; {mainPrice.current}
          <Icon
            title={t("EroCoins")}
            clazz={"color-main"}
            spritePath={"erocoin"}
            size={"xs"}
          />{" "}
          / {t("day")}
        </span>

        <div className="connectad">
          <div className={`connectad__day${isLow ? " low" : ""}`}>
            <span className="p2 color-main">{t("dailycharges")}</span>

            <InputWithArrows
              onChange={onChangeAuction}
              value={auctionPrice}
              onClickDown={handleClickDown}
              onClickUp={handleClickUp}
            />

            {/* <span className="p2">{t("paynow")}</span>

            <span className="title_h3 mb-0 text-center">
              {`${
                auctionPrice - currentPrice <= 1 ? 1 : auctionPrice - currentPrice
              }`}{" "}
              <Icon
                title={t("EroCoins")}
                clazz={"color-main"}
                spritePath={"erocoin"}
                size={"s"}
              />
            </span> */}
          </div>

          <span className={`p2 connectad__error${!isLow ? " low" : ""}`}>
            {t("dailycharges")} {t("minbet")} &mdash;{" "}
            <strong>{mainPrice.current} €</strong>
          </span>


          <span className="p2 color-main">{validNew}</span>
          <div className={`connectad__form${full ? ' _empty-date' : ''}`}>
            <div className="connectad__form__check">
              <Checkbox
                title={t("indefinitely")}
                onClick={onChangeFull}
                checked={full}
              />
            </div>
            
            <fieldset>
              <legend>{t("untildate")}</legend>
              <InputInLabel
                type="date"
                disabled={full}
                onChange={onChangeDate}
              >
              </InputInLabel>
            </fieldset>
          </div>
        </div>

        <Button
          size={"l"}
          clazz={"button--green justify-center"}
          disabled={isLow || (!full && !date)}
          onClick={(e) => onSubmit(e)}
        >
          {t("confirm")}
        </Button>
      </Popup>

      {zeroBalance &&
        <MinBalancePopup
          open={zeroBalance}
          setOpen={setZeroBalance}
        />
      }
    </>
  );
};

export default ConnectPromotionAdPopup;
