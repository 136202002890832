import React, {useCallback, useEffect, useRef, useState} from "react";
import InputMask from "react-input-mask";
import {useSelector} from "react-redux";
import debounce from "lodash.debounce";
import {getRegistrationStatus} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import "./inputInLabel.scss";
import { maskPhone, setMask } from "../../../helper/mask";
import { current } from "@reduxjs/toolkit";
const PhoneInput = (props) => {
    const {
      onChange,
      id,
      clazz,
      register,
      children,
      size = "l",
      autocomplete,
      disabled,
      value,
      maskValue = "",
      valueLoad,
      setError,
      errorType,
      required,
      setInputComplete = false,
      clearErrors = false,
      inputRef = null,
      isModal = false,
    } = props;

    const registrationState = useSelector(getRegistrationStatus);
    const [phoneMaskValue, setPhoneMaskValue] = useState(maskValue);
    const phoneRef = useRef(null);
    const { t } = useLadyService();

   
    const defaultProps = {
        id: id,
        onChange: onChange,
        disabled: disabled,
        value: value,
      };

    const isPhoneValid = useCallback(

        debounce((value, newMatrix) => {

          const cleanedValue = value.replace(/\D/g, "");
          const digitCountInMatrix = newMatrix.replace(/[^#\d]/g, "").length;
          const actualDigitsInValue = cleanedValue.length;
    
          if (actualDigitsInValue !== digitCountInMatrix) {

            setError(errorType, {message: t('phoneuncorrenct')});
            if (setInputComplete) {

              setInputComplete(false)
            }
          } else {

            if (setInputComplete) {

              setInputComplete(true)
            }
            if (clearErrors) {
                
              clearErrors(errorType)
            }
          }
        }, isModal ? 500 : 0)
    );


    


    // useEffect(() => {
    //   if (phoneRef.current) {
    //     maskPhone.call(phoneRef.current);
    //     setTimeout(() => {
    //       setPhoneMaskValue(maskValue);
    //       maskPhone.call(phoneRef.current);
    //     }, 300);
    //   }
    // }, [phoneRef, valueLoad]);

    useEffect(() => {
      if (phoneRef.current) {
        maskPhone.call(phoneRef.current);
      }
    }, [phoneRef]);


    useEffect(() => {
        if (registrationState) {
          setPhoneMaskValue("");
        }
      }, [registrationState]);

    useEffect(() => {
       if (valueLoad) {
        setPhoneMaskValue(maskValue);
        if (phoneRef.current) {
            maskPhone.call(phoneRef.current); // Format the phone number immediately
        }
    }
    }, [valueLoad, maskValue]);

    const handleChange = (event) => {
      const newValue = event.target.value;
      setPhoneMaskValue(newValue);
      onChange(newValue);
      isPhoneValid(newValue, phoneRef.current?.mask);
    };

    useEffect(() => {
        if (!!phoneRef?.current) {
          const inputId = phoneRef?.current.id;
          if (inputId) {
            let inputs = document.querySelectorAll(`#${inputId}`);
            inputs.forEach((input) => {
              if (!input.value) input.value = "+";
              input.addEventListener("input", setMask);
              input.addEventListener("focus", setMask);
              input.addEventListener("blur", setMask);
              input.addEventListener("change", setMask);
            });
          }
        }
      }, [phoneRef]);


    return (<>
        <label
          ref={inputRef}
          htmlFor={id}
          className={
            `input-label${ 
                clazz ? ` ${clazz}` : ""
            }${
                size ? ` size-${size}` : ""
            }${ 
                required ? " required" : "" 
            }${
                disabled ? " disabled" : ""
            }`
        }
        >
            {children}

            <InputMask
              {...register}
              {...defaultProps}
              maskPlaceholder={""}
              placeholder={"+420 000 000 000"}
              type={"tel"}
              value={phoneMaskValue}
              ref={phoneRef}
              className={`${disabled ? "disabled" : ""}`}
              autoComplete={autocomplete}
              onChange={handleChange}
            />
        </label>
    </>);
}

export default PhoneInput;