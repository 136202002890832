import { InputInLabel } from "@/components/ui";
import { Popup } from "../index";
import { ExternalLink } from "@/components/ui";
import { Fragment, useState } from "react";
import ImportAdComponent from "@/helper/ImportAdComponent";
import useLadyService from "@/services/LadyService";
import { LoadingButton } from "../../ui";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "eurogirlsescort.com" },
  { name: "escorts.cz" },
  { name: "dobryprivat.cz" }
];


const ImportAdPopup = (props) => {
  const { open, setOpen } = props;
  const [importAdValue, setImportAdValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { importAd } = ImportAdComponent();
  const [importFieldError, setImportFieldError] = useState(false);
  const { t } = useLadyService();

  const handleImport = async () => {
    const result = await importAd(importAdValue, setLoading);
    if (result === "error-empty") {
      setImportFieldError(true);

      return;
    }
    if (result) {
      setOpen(false);
    }
  };

  return (
    <Popup open={open} setOpen={setOpen} clazz={"gap-16"}>
      <h2 className="mt-48">{t("importfor")}</h2>
      <div className="popup-form__inner">
        <div className="popup-form__import text-left">
          <p className={"color-700 p2"}>
            {t('avaliblesites')}:
          </p>
          <ul className="text-left">
            {options.map((i, index) => {
              return (
                <li key={index}>
                  <a
                    href={`https://${i.name}`}
                    className="text-left"
                    target="_blank"
                  >{`https://${i.name}`}</a>
                </li>
              );
            })}
          </ul>
        </div>

        <fieldset>
          <legend>{importFieldError ? t("required") : t("url")}</legend>
          <InputInLabel
            type={"text"}
            size={"l"}
            id={"import-ad-popup"}
            placeholder={t("addLink")}
            value={importAdValue}
            onChange={(e) => {
              if (importFieldError) {
                setImportFieldError(false);
              }
              setImportAdValue(e.target.value);
            }}
            clazz={`w-100 link ${importFieldError ? "error" : ""}`}
          >
          </InputInLabel>
        </fieldset>
        <LoadingButton
          isLoading={loading}
          size={"l"}
          clazz={"button--green justify-center mt-16"}
          onClick={() => handleImport()}
        >
          {t("import")}
        </LoadingButton>
      </div>
    </Popup>
  );
};

export default ImportAdPopup;
