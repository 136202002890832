import {Link, useNavigate} from "react-router-dom";
import {Button, ButtonLink, Icon, Tag, UserBadge} from "@/components/ui";
import {format, fromUnixTime} from "date-fns"
import ResStatus from "./ResStatus";
import {wordEndings} from "../../../../helper/wordEndings";
import {CheckPhonePopup} from "../../../popups";
import React, {useState} from "react";
import useLadyService from "../../../../services/LadyService";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import "./resContainer.scss";

const statusObj = {
  0: "workstatus",
  1: "approvestatus",
  2: "declinestatus",
  3: "completestatus",
};

const typeObj = {
  1: "incall",
  2: "onclient",
  3: "accompaniment",
};

const ResContainer = (props) => {
  const {data, setData} = props;

  const {
    from,
    price: priceFrom,
    whatsapp,
    phone,
    profile,
    book_at,
    status,
    prepay,
    created_at,
    address,
    id: resId,
    reviews = [],
  } = data;

  const {
    name: nameFrom,
    // phone: phoneFrom,
    rating: ratingFrom,
    tariff: tariffFrom,
    // whatsapp: whatsappFrom,
  } = from;

  const phoneRes = formatPhoneNumberIntl(`+${phone}`);

  const {duration, price, type} = priceFrom;

  const {name: nameProfile, photo: photoProfile, slug: slugProfile} = profile;

  const [isOpenCheckPhone, setIsOpenCheckPhone] = useState(false);

  const {t, setMediaSize, windowWidth} = useLadyService();

  let classVariant;
  switch (statusObj[status]) {
    case "approvestatus": {
      classVariant = " approve";
      break;
    }

    case "completestatus": {
      classVariant = " approve";
      break;
    }

    case "declinestatus": {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  const handleOpen = () => {
    setIsOpenCheckPhone(true);
  };

  const bookAt = format(fromUnixTime(book_at), 'dd.MM.yyyy HH:mm');
  const startAt = format(fromUnixTime(created_at), 'dd.MM.yyyy HH:mm');

  const durRes =
    duration >= 60
      ? `${t("onthe")} ` +
      duration / 60 +
      " " +
      wordEndings(duration / 60, [t("hour"), t("hourfew"), t("hourmany")])
      : `${t("onthe")}  ${duration} ${t("minit")}`;

  return (
    <>
      <div className={"res__root"}>
        <div className={`res__user__main${classVariant}`}>
          <div className="res__user__block">
            <UserBadge
              props={{
                slug: tariffFrom,
                name: nameFrom,
                score: ratingFrom
              }}
            />

            <span className={'p2 color-main'}>{startAt}</span>
          </div>

          <div className="res__user__block">
            {!!whatsapp ? (
              <div className="res__user-contacts fd-column">
                <Link to={`tel:${phone}`} className={"hover-line"}>
                  <b>{phoneRes}</b>
                </Link>

                <ButtonLink
                  href={`https://wa.me/${whatsapp}`}
                  clazz={"button--primary"}
                  size={"xs"}
                  target={"_blank"}
                >
                  <Icon
                    size={"m"}
                    spritePath={"whatsapp"}/>
                  {whatsapp}
                </ButtonLink>

                <Button
                  size={"xs"}
                  buttonType={"submit"}
                  clazz={"button_outline--green mt-6 mb-6"}
                  title={t("crossnumber")}
                  onClick={handleOpen}
                >
                  {t("crossnumber")}
                </Button>
              </div>
            ) : (
              <div className="res__user-contacts fd-column">
                <Link to={`tel:${phone}`} className={"hover-line"}>
                  <b>{phoneRes}</b>
                </Link>
                <Button
                  size={"xs"}
                  buttonType={"submit"}
                  clazz={"button_outline--green mt-6 mb-6"}
                  title={t("crossnumber")}
                  onClick={handleOpen}
                >
                  {t("crossnumber")}
                </Button>
              </div>
            )}

           
          </div>
        </div>

        <div className={`res__info__block${classVariant}`}>

          <div className={`res__info__block__money${classVariant}`}>
            <div className="res__price ">
              <span className={'p2'}>{t("dateandtime")}</span>
              <span className="p1 color-main">{bookAt}</span>
            </div>
            <div className="res__price">
              {address && (
                <>
                  <span className="p2">{t('fulladdress')}</span>
                  <Link
                    target={'_blank'}
                    to={`//www.google.com/maps/search/?api=1&query=${address.replace(/ /g, '+')}`}
                    title={t('viewonmap')}
                    className="girl-card__address color-main p1 hover-line mb-4"
                  >
                    <Icon spritePath={"map"} size={"xs"} clazz={'mr-8'}/>{address}
                  </Link>
                </>
              )}
              <div className="res__var">
                <Tag clazz={'p2'}>
                  {t(`${typeObj[type]}`)}
                </Tag>
                <span className="color-main">{durRes}</span>
              </div>
            </div>

            <div className="res__price">
              <span className={'p2'}>{t("prepayment")}</span>
              <span className={'title_h3 currency--erocoin'}>{prepay}</span>
            </div>

            <div className="res__price">
              <span className={'p2'}>{t('onplace')}</span>
              <span className={'title_h3'}>{price - prepay}{' '}€</span>
            </div>
          </div>

          <Link
            to={`/profile/${slugProfile}`}
            target={"_blank"}
            className="res__ad"
            alt="ad"
          >
            <img
              src={setMediaSize(photoProfile, "xs")}
              className="res__ad__photo"
              alt="ad"
            />
            <span title={nameProfile} className="p1 mt-12 res__ad__title color-green text-dots">
              {nameProfile}
            </span>
          </Link>
        </div>
        

        <ResStatus
          status={status}
          reviews={reviews}
          id={resId}
          setData={setData}
          book_at={book_at}
        />
      </div>

      <CheckPhonePopup
        open={isOpenCheckPhone}
        setOpen={setIsOpenCheckPhone}
        phoneClient={phone}
      />
    </>
  );
};

export default ResContainer;
