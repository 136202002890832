import { Helmet } from "react-helmet";
import { Button, ButtonLink, Icon } from "@/components/ui";
import {
  getPartyAccessStatus,
  setPartyAccess,
} from "@/stores/slices/popupSlice";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../Popup";
import useLadyService from "@/services/LadyService";


const PartyAccess = () => {
  const { t } = useLadyService()
  const dispatch = useDispatch();
  const popupState = useSelector(getPartyAccessStatus);
  const handleClose = () => {
    dispatch(setPartyAccess(false))
  }
  return (
    <Popup
      id={"partyAccess"}
      open={!!popupState}
      setOpen={handleClose}
      className="popup-form"
      container={'div'}
    >

        <h2 className="popup-form__title text-center mt-48">
          {t('viponly')}
        </h2>

        <ButtonLink
          to={"#"}
          size={"l"}
          clazz={"button--green td-none"}
        >
          {t('changetariff')}
        </ButtonLink>
    </Popup>
  );
};

export default PartyAccess;
