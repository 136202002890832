import { useTranslation } from "react-i18next";

import { Button, InputInLabel } from "@/components/ui";

import { object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { makeRequest } from "@/services/makeRequest";

import showToast from "../../toast/Toast";

import { useState } from "react";

import Popup from "../Popup";
import {useSelector} from "react-redux";
import {getChangePasswordStatus, setChangePasswordStatus} from "../../../stores/slices/popupSlice";
import useLadyService from "../../../services/LadyService";

const ChangePasswordPopup = () => {
  const {dispatch, t} = useLadyService()

  const open = useSelector(getChangePasswordStatus)

  const [isLoading, setIsLoading] = useState(false);

  const [errorCurrent, setErrorCurrent] = useState("");

  const handleCloseDelete = () => {
    reset();
    dispatch(setChangePasswordStatus(false))
  };

  const formSchema = object().shape({
    old_password: string().min(8, t("min8")).required(),
    new_password: string().min(8, t("min8")).required(),
    password_confirmation: string()
      .min(8, t("min8"))
      .required()
      .oneOf([ref("new_password")], t("passdonot")),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    const { old_password, new_password } = data;

    const fetchData = async () => {
      try {
        const route = `user/change-password`;
        const method = "PUT";
        const payload = { current_password: old_password, new_password };

        await makeRequest({ route, method, payload });

        handleCloseDelete()

        showToast({
          message: t("pasupdate"),
          variant: "success",
        });
      } catch (error) {
        const res = error.response;

        switch (res.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          default:
          case 422: {
            setErrorCurrent(t("currentpasdecline"));
            break;
          }
        }
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      id="changePassword"
    >
      <h2 className="mt-48">{t("changepass")}</h2>

      <div className="popup-form__inner">
        <fieldset>
          <legend>{errorCurrent ? <span className={"popup-form__error"}>{errorCurrent}</span> : t("oldpass")}</legend>
          <InputInLabel
            clazz={`${!!errorCurrent ? " error" : ""}`}
            id={"oldPassword"}
            type={"password"}
            placeholder={"••••••••"}
            register={{...register("old_password")}}
          />
        </fieldset>
        

        <fieldset>
          <legend>{t("newpas")}</legend>
          <InputInLabel
            clazz={`${errors.password_confirmation ? " error" : ""}`}
            id={"newPassword"}
            type={"password"}
            placeholder={"••••••••"}
            register={{...register("new_password")}}
            autocomplete={"new-password"}
          />
        </fieldset>

        <fieldset>
          <legend>{errors.password_confirmation ? 
            <span className={"popup-form__error"}>
              {errors.password_confirmation.message}
            </span> 
            : t("repeatpas")}
          </legend>
          <InputInLabel
            clazz={`${errors.password_confirmation ? " error" : ""}`}
            id={"confirmationNewPassword"}
            type={"password"}
            placeholder={"••••••••"}
            register={{...register("password_confirmation")}}
            autocomplete={"new-password"}
          />
        </fieldset>
       
        <Button
          size={"l"}
          buttonType={"submit"}
          clazz={"button--green justify-center"}
        >
          {t("save")}
        </Button>
      </div>
    </Popup>
  );
};

export default ChangePasswordPopup;
