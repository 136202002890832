import { useLocation } from "react-router-dom";
import {useEffect, useState} from "react";
import {makeRequest} from "@/services/makeRequest";
import {Helmet} from "react-helmet"

const SEOBlock = ({initialData}) => {
    const location = useLocation();
    const pageSlug = location?.pathname.slice(4);
    let seo_data = false
    if (initialData?.seo !== undefined) {
        seo_data = initialData.seo
    }
    const [data, setData] = useState(seo_data);

    const fetchData = async () => {

        try {
          const route = `static/${pageSlug}`;
          const method = "GET";
  
          const res = await makeRequest({ route, method });
          if(res?.status !== 400){
            setData(res ?? false)
          }

        } catch (error) {
            console.log(error)
        }
      };

    useEffect(()=>{
       fetchData();
    }, [location])

    useEffect(()=>{
      if(document.title === data?.seo_title){
        return
      }
      else if(data?.seo_title){
        document.title = data?.seo_title;
      }
    }, [document.title])

    if(data){
        return (
          <>
            <Helmet>
              {data?.seo_title && (data?.seo_title !== document.title) && <title>{data?.seo_title}</title>}
              {data?.seo_description && <meta name="description" content={data?.seo_description} />}
            </Helmet>
            {data?.content && 
              <div 
                className="container w-100"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              > 
              </div>}
          </>
        )
    }
    else return
}

export default SEOBlock;
