import { Button, ErrorMessage, InputInLabel } from "@/components/ui";
import { Popup } from "@/components/popups";
import React from "react";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useLadyService from "@/services/LadyService";

const Participate = ({ party, isOpen, setIsOpen }) => {

    const { userPhone, userTg, t } = useLadyService()
    const formSchema = object()
        .shape({
            phone: string()
                .min(14, `${t("phonenumerrequired")}`)
                .test("phone-is-empty", t("phonenumerrequired"), function () {
                    return !!userPhone;
                }),
        });

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        clearErrors,
        setError
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
        defaultValues: {
            phone: userPhone,
            telegram: userTg
        }
    });
    const handleParticipate = async (data) => {
        console.log('data', data)
    }
    return (
        <Popup
            clazz={'p-24'}
            open={isOpen}
            container={'div'}
            id={"participatePopup"}
            setOpen={setIsOpen}>
            <>
                <h2 className="mb-12 mt-48">{t('wouldliketopart')}</h2>
                <p3 className="mb-24 text-center">{t('donotpaynow')}</p3>
                <form
                    className="participate-popup mb"
                    onSubmit={handleSubmit(handleParticipate)}
                >
                    <fieldset>
                        <legend>
                            {errors.phone ? <ErrorMessage message={errors.phone.message} /> : t("phonenumber")}</legend>
                        <InputInLabel
                            type={"tel"}
                            id={"create-phone"}
                            placeholder={"+XXX XXX XXX XXX"}
                            register={{ ...register("phone") }}
                            autocomplete={"tel"}
                            clazz={errors.phone?.message ? "_error" : ""}
                            maskValue={userPhone}
                            setMaskValue={(value) => {
                                if (value.length > 2) {
                                    setValue('phone', parseInt(value.replace(/\D/g, '')))
                                }
                            }}
                            setError={setError}
                            errorType={"phone"}
                            clearErrors={clearErrors}
                            required={true}
                        >

                        </InputInLabel>
                    </fieldset>
                    <fieldset>
                        <legend>{t("telegram")}</legend>
                        <InputInLabel
                            type={"text"}
                            id={"create-telegram"}
                            placeholder={""}
                            register={{ ...register("telegram") }}
                            autocomplete={"telegram"}
                            clazz={'mt-8'}

                        >
                        </InputInLabel>
                    </fieldset>
                    <Button
                        type={'submit'}
                        clazz={`button button-s button--green w-100`}
                        buttonType={"submit"}
                    >
                        {t('sendrequest')}
                    </Button>
                </form>
            </>
        </Popup>
    )
}

export default Participate;