import {useEffect, useRef} from "react";
import InputZone from "./InputZone";
import {InputInLabel} from "../../../../ui";
import useLadyService from "@/services/LadyService";
import {Video} from "@/components/ui";

export const MediaZone = ({
                            h3,
                            title,
                            subtitle,
                            isMulti = false,
                            isVideo = false,
                            setVideoPresentationValue = false,
                            videoPresentationValue = false,
                            isH3 = true,
                            isBigPhoto = false,
                            w_100 = false,
                            interior = false,
                            media = [],
                            setMedia,
                            removeMedia,
                            disabled,
                            verAdIndiPopup = false,
                            disableUpload = false
                          }) => {
  const {t} = useLadyService();
  const videoRef = useRef(null);

  const repeatInputRef = useRef(null);


  useEffect(() => {
    if (videoPresentationValue && repeatInputRef.current) {
      repeatInputRef.current.focus();
    }
  }, [repeatInputRef, videoPresentationValue]);

  const accept = isVideo
    ? {
      "video/mp4": [".mp4", ".MP4"],
      "video/quicktime": [".mov", ".MOV"],
      "video/x-ms-wmv": [".wmv", ".WMV"],
      "video/x-msvideo": [".avi", ".AVI"],
      "video/x-matroska": [".mkv", ".MKV"],
    }
    : {
      "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
      "image/png": [".png", ".PNG"],
      "image/webp": [".webp", ".WEBP"],
    };

  return (
    <Wrapper
      isH3={isH3}
      h3={h3}
    >
      {!!media.length && !isMulti && (
        <div
          className={`example__photo ${w_100 ? "w-100" : ""} ${
            isBigPhoto ? "example__photo-big" : ""
          } ${interior ? "example__photo-interior" : ""} ml-auto mr-auto`}
        >
          {isVideo ? (
            <video
              width={`${isBigPhoto ? "100%" : "340px"}`}
              height="208px"
              controls
              ref={videoRef}
              src={media}
            />
          ) : (
            <img src={media} alt=""/>
          )}
          <span
            className="icon icon-s icon--close icon-remove"
            onClick={() => removeMedia(media)}
          >
          </span>
        </div>
      )}

      {isMulti && (
        <div className="agency-interior__images">
          {media.map((item, index) => (
            <div
              key={index}
              className={`example__photo ${interior ? "example__photo-interior" : ""}`}
            >
              {typeof item === 'string' && item.includes("mp4") ? (  // Проверка, является ли item строкой
                <video
                  width={`${isBigPhoto ? "100%" : "340px"}`}
                  height="208px"
                  controls
                  ref={videoRef}
                  src={item}
                />
              ) : (
                <img src={item.url ? item.url : item} alt=""/>
              )}
              <span
                disabled={disabled}
                className="icon icon-s icon--close icon-remove"
                onClick={() => removeMedia(item)}
              >
              </span>
            </div>
          ))}

          <InputZone
            isMulti={isMulti}
            setVideoPresentationValue={setVideoPresentationValue}
            videoPresentationValue={videoPresentationValue}
            isVideo={isVideo}
            title={title}
            subtitle={subtitle}
            data={media}
            setData={setMedia}
            accept={accept}
            verAdIndiPopup={verAdIndiPopup}
            disableUpload={disableUpload}
          />
        </div>
      )}

      {!media.length && !isMulti && !videoPresentationValue && (
        <InputZone
          isMulti={isMulti}
          setVideoPresentationValue={setVideoPresentationValue}
          videoPresentationValue={videoPresentationValue}
          isVideo={isVideo}
          title={title}
          subtitle={subtitle}
          data={media}
          setData={setMedia}
          accept={accept}
          verAdIndiPopup={verAdIndiPopup}
          disableUpload={disableUpload}
        />
      )}

      {videoPresentationValue && (
        <div className="eroLink">
          <fieldset>
            <legend>{t("linktovideo")}</legend>
            <InputInLabel
              disabled={disabled}
              inputRef={repeatInputRef}
              type={"text"}
              id={"upload-agency-video-repeat"}
              clazz={"mb-12 align-start w-50 eroLink__input video-link"}
              value={videoPresentationValue}
              placeholder={"https://vimeo.com/93721124"}
              onChange={(e) => setVideoPresentationValue(e.target.value)}
            >
            </InputInLabel>
          </fieldset>

          <div className={`agency-card__img vid-image ${!videoPresentationValue ? 'img-wrapper' : ''}`}>
            <iframe class='thumbnail' src={videoPresentationValue}/>
          </div>

          {!videoPresentationValue && <span className="ero-status color-main text-center">{t("processing")}</span>}
        </div>
      )}
    </Wrapper>
  );
};


const Wrapper = ({children, isH3, h3}) => {
  if (isH3) {
    return (
      <div className="d-flex fd-column w-100">
        {h3 && <h3>{h3}</h3>}
        {children}
      </div>
    );
  } else {
    return children;
  }
};