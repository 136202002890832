import EditBlock from "../editBlock/EditBlock";
import React, {Fragment} from "react";
import AgencyCard from "../../../../cards/agencyCard/AgencyCard";
import ZeroZone from "../../../../zeroZone/ZeroZone";
import useLadyService from "../../../../../services/LadyService";


const ModelAgencyCard = ({props}) => {
  const {
    edit,
    setIsOpenEditAgency,
    agency
  } = props;

  const { t } = useLadyService();

  return (
    <div className={"model-card"}>
      {edit ? (
        <EditBlock
          title={t("workingforagency")}
          titleType={'h3'}
          onClick={() => setIsOpenEditAgency(true)}
          clazz={'align-center mb-16 justify-sb'}
        />
      ) : (
        <p className={'title_h2'}>{t("workingforagency")}</p>
      )}

      {!!agency?.length ?
        agency.map((data, index) => {
          return (
            <Fragment key={index}>
              <AgencyCard props={data} buttonColor={"button--tetriary"}/>
            </Fragment>
          );
        }) : edit ? (
          <ZeroZone
            mainText={t("addagency")}
            setOpen={setIsOpenEditAgency}
            disc={10}
          />
        ) : null}
    </div>
  )
}

export default ModelAgencyCard